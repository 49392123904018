/* LoadingCircle.css */
.loading-circle-container {
    width: 100vw; /* Set width to full viewport width */
    height: 100vh; /* Set height to full viewport height */
    position: fixed; /* Position fixed to keep it in place */
    top: 0;
    left: 0;
    display: flex; /* Use flexbox to center the loading circle */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    backdrop-filter: blur(5px); /* Apply backdrop blur */
  }
  
  .loading-circle {
    width: 100px; /* Adjust width and height for size of the loading circle */
    height: 100px;
    border: 4px solid #f3f3f3; /* Border color of the loading circle */
    border-top: 4px solid #3498db; /* Color of the loading circle */
    border-radius: 50%; /* Makes the div circular */
    animation: spin 1s linear infinite; /* Apply animation */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); } /* Starting point of the rotation */
    100% { transform: rotate(360deg); } /* Ending point of the rotation (full circle) */
  }
  