//Fonts
@font-face {
  font-family: 'Open Sans Regular';
  src: local('Open Sans Regular'), url("../../../assests/Font/Open_Sans/OpenSans-Regular.ttf") format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Noto+Sans+JP:wght@400;500;700;900&display=swap');

$fontFamily:  'Lato', 'Open Sans Regular',sans-serif;

//Colors

/* light theme */
$panel-bg:#ffffff;
$panel-box-shadow : 0 0px 6px #cee1ed; 
$panel-blue-bg:#CBDDED;
$panel-blue-box-shadow:none;
$panel-blue-border:1px solid #bbd0e3;
$panel-border:1px solid #C8DBE8;

$button-bg:#eef8fe;
$button-border:1px solid #deebf3;
$panel-text-color:#000000;

/* dark theme */
$primary-color-blue : linear-gradient(180deg, #008bee 0%, #0071c1 100%);
$primary-color-darkBlue : #0056A3;
$primary-color-white : white;
$primary-color-darkWhite : #9fbce2;
$primary-bg : #0b2a58;
$nav-bg : #0056A3;
$panel-bg : #0F3B7A;
$panel-box-shadow : none;
$dark-title-white:#f1f3f7;
$dark-title-higlight:#fafafa;
$dark-text-white:#c3d3eb;
$input-bg:#0a3065;
$input-color:#c3d3eb;
$input-border:#1c4784;
$form-border:#1e4b8b; 
$link-color:#26d7ff;
$link-btn-color:#88b7e8;
$link-btn-color-hover:#a4dff6;
$alert-bg : #0F3B7A;
$alert-cust-bg : #FFFFFF;
$form-border-highlight: #5374a2;
$alert-box-shadow-color : #0F3B7A;
$dropdown-bg:#004c8f;
$dropdown-border:#00427c;
 $dropdown-text-color-white:#fff;
$dropdown-text-color:#85b1d8;
$panel-helight-bg:#00bfad;
$panel-helight-border:#00bfad;
$panel-heiglight-text:#ffffff;

$pkg-item-box-shadow:0 0 10px #0b3774;
$pkg-item-border:#0d3978;
$pkg-item-bg:#1559ad;
$pkg-item-hover-bg:#3688ef;
$pkg-item-hover-border:#36c4ef;
$pkg-item-name-color:#ffffff;
$pkg-item-price-color:#ffffff;
$upgrade-btn-bg:#00b0d8;

$tab-button-heighlight:#1892ff;
$tab-button-normal:#1a52a1;
$tab-button-normal-text-color: #abceff;;

$panel-blue-bg:#0F3B7A;
$table-row-1:#1e4c8d;
$table-row-2:#134386;
$panel-blue-box-shadow:none;
$panel-blue-border:none;
$panel-blue-border-color:#2b5b9d;
$button-border:none;
$button-box-shadow:none;
$panel-border:none;
$button-bg:#1558ad;
$button-border:1px solid #0e3b7a;
$button-color: #ffffff;
$panel-text-color:#ffffff;
$thin-line-bg:#24518f;
$dark-text-blue:#aabdd9;
$table-row-color:#184a90;
$table-row-border-color:#184a90;

$select-text-border-color:#2b5b9d;
$select-text-color:#c3d3eb;
$select-bg-color:#0a3065;
$input-text-border-color:#2b5b9d;
$input-text-color:#c3d3eb;
$input-bg-color:#0a3065;

$panel-item-box-text-color:#ffffff;
$panel-item-box-bg:#2b548d;
$panel-item-box-border:#2b54;

$main-btn-selected:#008bee;
$main-btn-bg:#1558ad;
$main-btn-text-color:#a4c4eb;

$table-border-color:#1558ad;
$gift-data-container-bg:#2c5793;
$gift-data-text-color:#ffffff;

$common-container-bg:#2c5793;
$common-containe-border-color:#315f9f;
$common-container-text-color:#ffffff;