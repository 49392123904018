.noDataToShow {
  margin-top: 2rem;
  min-width: 300px;

  > div {
    display: flex;
    flex-direction: column;
    align-content: center;
    min-height: 250px;


    > div {
      margin: auto;
      > img {
        &.common{
          width: 100px;
          height: 100px;
        }
        &.real{
          width: 300px;
          height: 50px;
        }
      }

      > div {
        font-weight: bold;
      }
    }


  }


}

.loadingAnimation{
  max-height: 430px;
  min-height: 390px;
  height: 100%;
  background: $panel-bg;
  display: flex;
  justify-content: center;
  margin-top: 0.6rem;
  //box-shadow: 0 0 6px #cee1ed;
  //border: 1px solid #C8DBE8;
  box-sizing: border-box;
  border-radius: 8px;
  &.borderedNo{
    box-shadow: none;
    border: none;
    border-radius: 0;

  }

  > div {
    margin: auto;
  }
}