.mainViewLTE {
    max-width: 1200px;
    background: $panel-bg;
    margin: 0 auto;
    margin-top: 40px;
    padding: 20px;
    // box-shadow: 0 0 6px #cee1ed;
    border-radius: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 100%;
    position: relative;

    .header {
        display: flex;
    
        .btn-back {
          display: flex;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          padding: 0 10px;
          color: #7e7e7e;
          border: 1px solid #7e7e7e;
          width: fit-content;
          border-radius: 30px;
          line-height: 26px;
          cursor: pointer;
          opacity: 0.8;
          transition: all 100ms ease-in-out;
    
          &:first-child div {
            margin-right: 6px;
          }
    
          &:hover {
            color: #4b4b4b;
            border-color: #4b4b4b;
          }
        }
    
        .title {
          // font-style: normal;
          font-weight: 600;
          font-size: 20px;
          color: $dark-title-higlight;
          font-size: 16px;
          // margin-top: 10px;
          margin-left: 10px;
        }
    }

    .navigation {
        width: 100%;
        height: 100%;
        display: flex;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $form-border;
    
    
        .wizard-navigation-set {
          width: 100%;
    
          .wizard-item-container {
            width: fit-content;
            display: flex;
            flex-direction: row;
            margin: 0 auto;
    
            .wizard-item {
              display: flex;
              flex-direction: row;
              position: relative;
              transition: all 100ms ease-in-out;
              @include screen("medium") {
               display: block;
              }
    
              .item-icon {
                width: 30px;
                height: 30px;
                background-color: #18488a;
                border-radius: 30px;
                color: #7699ca;
                margin-right: 6px;
                position: relative;
                text-align: center;
                line-height: 30px;
                transition: background-color 100ms ease-in-out;
                @include screen("medium") {
                  // display: none;
                  margin: auto;
                  margin-bottom: 4px;
                }
                &::before {
                  content: "";
                  position: absolute;
                  width: 100%;
                  height: 2px;
                  top: 14px;
                  background-color: #476691;
                  left: -36px;
                  @include screen("medium") {
                    display: none;
                  }
                }
              }
    
              .item-name {
                font-size: 14px;
                font-weight: 600;
                line-height: 30px;
                margin-right: 42px;
                position: relative;
                color: #4772a3;
                transition: all 100ms ease-in-out;
                @include screen("medium") {
                  text-align: center;
                  line-height: 20px;
                  margin-right: 0px;
                  padding: 0 10px;
                }
                &::after {
                  // content: "";
                  // position:absolute;
                  // width:100%;
                  // height: 2px;
                  // top:14px;
                  // background-color: #333;
                  // right: -30px;
                }
              }
    
              &:first-child
              .item-icon {
                &::before {
                  display: none;
                }
              }
    
              &.active {
                .item-icon {
                  background-color: #1976ce;
                  color: #ffffff;
    
                  &::before {
                    background-color: #b6b6b6;
                  }
                }
    
                .item-name {
                  color: #ffffff;
                }
              }
    
              &.done {
                .item-icon {
                  background-color: #4db848;
                  color: #ffffff;
                  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAALCAYAAACgR9dcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAKlJREFUKJGV0DuKQkEQBVBdhDirUDGSQTA2kQlFZAIzd2FkaOAaBFfiAkRwC4P4CTQZv8fAFpsH8nyVNHTVuVCVy2UsNLDCNCus4+BRqyzwG/sA/9H8FFaxC/CI1rPRxwRfb2AF2wBP+Imbzx2WKCZgGZvQv6CdTB541QKF8F/COoKddzsNo4A5aviLYDftKKMo4BzeK34/uWge4yjghl4qTATMAhynzd8BBJsBlakQ16kAAAAASUVORK5CYII=');
                  background-repeat: no-repeat;
                  background-position: center;
                  text-indent: -99999px;
    
                  &::before {
                  }
                }
    
                .item-name {
                  color: #969696;
                  opacity: 0.5;
                }
              }
              &.failed {
                .item-icon {
                  background-color: #ff5555;
                  color: #ffffff;
                  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAOCAYAAADqtqZhAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAEBJREFUCJmtzqEVwCAQwNCUh+90jMcSLMIo9ER1KjjR+n4XF9Rwi0OVVHj5NyowgRMIPtSmDrWhXvm2CtCBG+gPaisqEE7ZLEgAAAAASUVORK5CYII=');
                  background-repeat: no-repeat;
                  background-position: center;
                  text-indent: -99999px;
    
                  &::before {
                  }
                }
    
                .item-name {
                  color: #ff5555;
                  opacity: 1;
                }
              }
            }
          }
        }
    
        > div {
          position: relative;
          display: flex;
          align-items: center;
          width: 25%;
          justify-content: center;
    
          .bar {
            width: 100%;
            height: 11px;
            border: 1px solid #1553AB;
            box-sizing: border-box;
    
            &.blue {
              background: #01539E;
              animation-name: example;
              animation-duration: 2s;
            }
    
          }
    
          .icon {
            width: 52px;
            height: 52px;
            background: #FFFFFF;
            border: 1px solid #1553AB;
            box-sizing: border-box;
            position: absolute;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
    
            > svg {
              > path {
                fill: #0056A3;
              }
            }
    
            &.blue {
              background: linear-gradient(270deg, #01539E 0%, #0B2A58 100%);
              //background: #01539E;
              animation-name: example;
              animation-duration: 2s;
    
              > svg {
                > path {
                  fill: #FFFFFF;
                }
              }
            }
    
          }
        }
    }

    .bodyLTE {
        width: 100%;
        max-height: 100%;
        position: relative;
    
        .title {
          @include subBodyBackgroundTitle()
        }
    
        @include customAlertViewTemp();
    
        .termConditon {
          //position: absolute;
          width: calc( 100% - 20px);
          height: auto;
          border-radius: 10px;
          background: rgb(163 199 234 / 24%);
          mix-blend-mode: normal;
          -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
          //display: flex;
          justify-content: center;
          align-items: center;
          z-index: 5;
          box-sizing: border-box;
          margin: 10px 0 0 10px;
          overflow-y: scroll;
    
          @include scroll-bar-styles();
    
          .headerTerm {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            color: white;
            margin: 0.6rem;
          }
    
          .bodyList {
            padding: 0.6rem;
            .title {
              @include subBodyBackgroundTitle()
            }
    
            .discription {
              margin: 0 0 15px 0;
              text-align: justify;
              color: white;
    
            }
    
          }
          @media only screen and (max-width: 768px) {
            margin: -117px 0 0 -11px;
          }
    
        }
    
    }

    .singleInput {
        // display: flex;
        // align-items: center;
        padding: 10px;
        width: 100%;
    
    
        > label {
          // position: absolute;
          display: inline;
          //width: 50%;
        }
    
         .error-msg {
          position: absolute;
          margin-left: 200px;
          // margin-right: 0;
          margin-top: -20px;
          // float: right;
          color: red;
          font-size: 14px;
        }
    
        > input {
          // margin-left: 100px;
          // padding-left: 20px;
          width: 100%;
    
          &[type='radio'] {
            background: #2FD664;
            width: 20px;
            height: 20px;
            padding: 5px;
            margin-left: 90px;
          }
    
    
        }
    
        > select {
          color: black;
        }
    
    
    
        .animation {
          animation-name: success;
          animation-duration: 2s;
        }
    
    
        &.title-input {
          width: 120px;
    
          select {
            width: 100px;
            color: black;
            height: 40px;
            border: 0.5px solid #d9e5f0;
            box-sizing: border-box;
            border-radius: 7px;
            background: none;
          }
        }
    
        &.radio-input {
          width: fit-content;
          display: flex;
          margin: 10px 0 0 0;
    
          input {
            margin-left: 0px;
            min-width: 20px !important;
            cursor: pointer;
          }
    
          label {
            display: initial;
            margin-left: 6px;
            line-height: 20px;
            cursor: pointer;
          }
        }
    
    
    .input-field{
    
        .input-field {
    
    
          input {
            width: 100%;
            min-width: 40px;
            height: 40px;
            border: 0.5px solid #d9e5f0;
            box-sizing: border-box;
            border-radius: 7px;
            padding: 0 10px;
            outline: none;
          }
        }
    
      }
    
      button {
        width: 84px;
        height: 34.59px;
        background: linear-gradient(180deg, #1979D1 0%, #1658AD 100%);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        color: #FFFFFF;
      }
    
      .actions {
        width: 50%;
        display: flex;
        justify-content: center;
        margin: auto;
    
        > button {
          margin: 2rem;
    
          &.noMargin {
            margin: 0;
          }
        }
    
        > form {
          > button {
            margin: 2rem;
    
            &.noMargin {
              margin: 0;
            }
          }
        }
      }
    }

    @keyframes example {
        from {
          background-color: white;
        }
      
        to {
          background-color: #01539E;
        }
      }
      
      @keyframes success {
        from {
          color: white;
        }
      
        to {
          color: #2FD664;
        }
      }

}