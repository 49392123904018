input {
  width: 100%;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  border: 0.5px solid $input-border;
  box-sizing: border-box;
  border-radius: 7px;
  outline: none;
  font-size: 14px;
  color: $dark-text-white;
  padding-left: 10px;
  background-color: $input-bg;
  color:$input-color;
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a8cbff;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #a8cbff;
  }
  &.pLeft {
    padding-left: 50px;
  }

  &[type='checkbox'].sign {
    width: 18px;
    height: 18px;

  }

}

select {
  min-width: 40px;
  height: 40px;
  border: 0.5px solid #D6D6D6;
  box-sizing: border-box;
  border-radius: 7px;
  outline: none;
  font-size: 14px;
  color: #4c4c60;
  padding-left: 10px;
  padding-right: 20px;
  cursor: pointer;

  &.fancy{
    background: linear-gradient(270deg, #0B2A58 0%, #0056A3 100%);
    border-radius: 40px;
    color: #fff;

  }

  // &.recurrent{
  //   background: linear-gradient(270deg, #0B2A58 0%, #0056A3 100%);
  //   border-radius: 40px;
  //   color: #fff;text-justify: auto;
  // }

  & option{
    background: linear-gradient(270deg, #0B2A58 0%, #0056A3 100%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

    color: $input-color;
  }

}
select::-ms-expand {
  display: none;
}
.otp {
  width: 40px;
  height: 55px;
  background: white;
  color: #1977CE;
  font-size: 36px;
  font-weight: bold;
  cursor: none;
  margin: 5px;
  border: 1px solid #1977CE;
  @media only screen and (max-width: 600px) {
    margin: 1px;

  }
}


label {
  font-size: 14px;
  color: $dark-text-white;
  font-weight: 600;

  &.red {
    color: red;
  }

  &.white {
    color: $primary-color-white;
  }
  &.large {
    font-size: 18px;
  }
}


.error-message {
  font-size: 13px;
  color: #FF4E4E;
  font-weight: 600;
}

.error {
  border: 0.5px solid #ffbdbd;
  background-color: #fff5f5;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #c3d3eb !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}