.bbSubBody {
  margin-top: 0.6rem;
  box-shadow: $panel-box-shadow;
  border: $panel-border;
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 530px;
  height: auto;
  background: $panel-bg;
  color: $panel-text-color;
  position: relative;

  .body-container {
    padding: 10px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: $dark-title-white;
      padding: 2px 0 10px 2px;
    }
    .wrapper {
      width: 100%;
      height: auto;
      border: 1px solid $panel-blue-border-color;
      box-sizing: border-box;
      border-radius: 7px;
      padding: 15px;
    }
  }
  .monthRow {
    display: flex;
    justify-content: space-between;
    margin: 0 1rem 0 1rem;

    & div {
      background: #3b7bcc;
      width: 100%;
      margin: 1rem;
      padding: 4px;
      color: #000000;
      cursor: pointer;
      text-align: center;

      &.current {
        background: #4acdff;
        //color: #1B2AA2;
        font-weight: bold;
      }
    }
  }

  .colorRow {
    margin: auto;
    display: flex;
    max-width: 80%;
    // margin: auto;
    justify-content: space-evenly;
    justify-items: center;
    gap: 1rem;
    // margin: 1rem;
    & div {
      display: flex;
      align-items: center;
      gap: 5px;
      &.bullet {
        min-width: 12px;
        height: 12px;
        border-radius: 50%;
      }
    }
  }

  .table {
    width: 98%;
    margin: 0.6rem;

    .usage {
      width: 65%;
      text-align: center;
    }
    tr {
      td {
        .btn-more {
          background-color: $main-btn-bg;
          color: $main-btn-text-color;
        }
      }
      .link {
        color: $primary-color-darkWhite;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .tableBody {
      font-size: 9px;
      color: $panel-text-color;
    }
  }

  .mainBar {
    width: 80%;
    margin: 1rem auto;
  }

  .rowCharts {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    gap: 20px;
    width: 98%;
    margin: auto;
    > div {
      flex: 40%;
      margin-bottom: 10px;
      max-width: 400px;
    }

    .speed {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      > div {
        width: 40%;
        display: flex;
        row-gap: 10px;
      }
    }
  }

  .oneDayViewBody {
    margin: 2rem;
    min-height: 375px;

    .leftSide {
      width: 30%;
      min-height: 400px;
      float: left;
      border: 1px solid #c8dbe8;
      box-sizing: border-box;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      border-radius: 5px;

      .singleRow {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .colorView {
          width: 15px;
          height: 12px;
          background: green;
        }

        .text {
          width: 100%;
          margin: 0 5px;
        }
      }
    }

    .rightSide {
      width: 66%;
      min-height: 400px;
      float: right;
      border: 1px solid #c8dbe8;
      box-sizing: border-box;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      border-radius: 5px;

      .topButtons {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;

        button {
          margin: auto;
          width: 120px;
          height: 40px;
          background: $primary-color-white;
          color: #707070;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          font-weight: bold;
          font-size: 16px;

          &.blue {
            background: #0056a3;
            color: $primary-color-white;
          }
        }
      }

      .chart {
        height: 280px;
        margin-top: 40px;
        color: white;
        .labelStyle {
          font-size: 12px;
        }
      }
    }
  }

  @include customAlertView();

  .progress-bar {
    //cursor: pointer;
    .bg-success {
      cursor: pointer;
    }
  }
}
