.dataAddOns {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background: $primary-color-white;
  margin-top: .6rem;
  box-shadow: 0 0 6px #cee1ed;
  position: relative;
  z-index: 0;

  &.noPadding {
    position: relative;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      @include subBodyBackgroundTitle();
    }

    padding-top: 0;
    @include customAlertViewTemp();
  }

  .switch-button {
    background: #C4C4C4;
    border-radius: 10px;
    overflow: hidden;
    width: 263px;
    line-height: 26px;
    height: 36px;
    text-align: center;
    font-size: 13px;
    letter-spacing: 1px;
    position: relative;
    padding-right: 110px;


    &:before {
      content: "Recurrent";
      font-size: 13px;
      color: $primary-color-white;
      font-weight: bold;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 141px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      pointer-events: none;
    }

    &-checkbox {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;

      &:checked + .switch-button-label:before {
        transform: translateX(130px);
        transition: transform 300ms linear;
      }

      & + .switch-button-label {
        position: relative;
        padding: 5px 0;
        display: block;
        user-select: none;
        pointer-events: none;

        &:before {
          content: "";
          background: $primary-color-blue;
          height: 100%;
          width: 95%;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 8px;
          transform: translateX(0);
          transition: transform 300ms;
        }

        .switch-button-label-span {
          position: relative;
        }
      }
    }
  }

  .title {
    @include subBodyBackgroundTitle();
  }

  @include customAlertViewTemp();

  .addon-container {
    display: flex;

    @include screen("medium") {
      display: block;
      padding-bottom: 1px;
    }

    .leftSide {

      padding: 5px;
      margin-bottom: -10px;

      .buttonTab-wrapper {

        display: flex;
        padding: 5px;

        &:last-child {
          padding-right: 10px;
        }

        .buttonTab {
          box-sizing: border-box;
          border-radius: 10px;
          flex-direction: column;
          width: 160px;
          height: 60px;
          text-align: center;
          text-decoration: none;
          color: $panel-text-color;
          font-weight: 600;
          z-index: 2;
          // border: 1px solid #deebf3;
          background-color: #275596;
          cursor: pointer;
          position: relative;

          &.blue {
            background: $primary-color-blue;
            color: $primary-color-white;
          }

          & div {
            margin: auto;
            font-size: 15px;
          }

          & span {
            position: absolute;
            display: block;
            height: 20px;
            top: 0;
            bottom: 0;
            right: -13px;
            margin: auto;
          }

          &:last-child {
            // margin-bottom: 0.8rem;
          }


          @include screen("medium") {
            display: flex;
            width: initial;
            min-height: 82px;
          }
        }

        @include screen("medium") {


          display: block;
          flex-basis: 33%;
          padding: 5px;

        }
      }

      @include screen("medium") {
        width: 100%;
        display: flex;
        & span {
          display: none !important;
        }
      }


      @include screen("medium") {
        flex-flow: row wrap;
        justify-content: space-between;
      }
    }

    .rightSide {
      flex: 1 1;

      .addon-items {
        grid-area: graphbody;
        border: 1px solid $panel-blue-border-color;
        box-sizing: border-box;
        border-radius: 8px;
        margin: 0.8rem 0.8rem 0.8rem 0;
        width: auto;
        height: auto;
        min-height: 416px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: relative;

        @include screen("medium") {
          margin: 0.8rem;
          min-height: auto;
        }

        > .item-container {

          padding: 10px;
          width: 50%;
          box-sizing: border-box;
          float: left;
          position: relative;
          @include screen("medium") {
            width: 100%;
          }

          .item {
            border: 1px solid $panel-blue-border-color;
            border-radius: 10px;

            .title {
              width: 100%;
              background: $alert-bg;
              border-radius: 10px 10px 0 0;
              font-size: 16px;
              color: #565656;
              font-weight: bold;
              padding: 9px 20px;
            }

            .banner-container {
              position: relative;
              height: 210px;
              background-size: cover;
              border-radius: 0 0 10px 10px;
              background-position: center;
              background-color: #08356b;

              .imageView {
                width: 100%;
                max-height: 200px;
                min-height: 240px;
                border-radius: 0 0 10px 10px;
              }

              .priceTag {
                position: absolute;
                width: fit-content;
                height: fit-content;
                text-align: center;
                font-weight: bold;
                font-size: 20px;
                line-height: 27px;
                color: #FFFFFF;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
              }
            }
          }
        }
      }
    }
  }
}