.popup-footer {
  width: 100%;
  display: flex;

  .container-right {
    float: right;
    margin-left: auto;
    padding: 20px 0 0 0;

    .btn-ok {
      width: initial;
      height: initial;
      padding: 8px 25px;
      margin-left: 10px;
      background: #1979D1;
      box-shadow: none;
      border-radius: 7px;
      font-style: normal;
      border: none;
      font-weight: normal;
      font-size: 15px;
      text-align: center;
      color: #FFFFFF;
      transition: all .3 ease;
      @include set-btn-click-animation(0.97);


    }
  }

}

.header {
  display: flex;
}

.container-footer {
  width: 100%;
  display: flex;
  padding: 14px;
  border-top: 1px solid $form-border;

  .action-container {
    display: flex;

    &.right {
      margin-top: 40px;
      float: right;
      margin-left: auto;

      button {
        width: initial;
        height: initial;
        padding: 8px 25px;
        margin-left: 10px;
        background: #1979D1;
        box-shadow: none;
        border-radius: 7px;
        font-style: normal;
        border: none;
        font-weight: normal;
        font-size: 15px;
        text-align: center;
        color: #FFFFFF;
        transition: all .3 ease;
        @include set-btn-click-animation(0.97);

        &:disabled {
          transition: all .3 ease;
          cursor: not-allowed;
          background: #9cafc7;
          color: #ffffff;
          opacity: 0.5;
        }

        &.btn-back {
          background-color: #ffffff;
          border: 1px solid #bebebe;
          color: #929292;
          
        }

        &.btn-cart {
          background-color: #2FD664;
          // background: linear-gradient(180deg, #2FD664 0%, #0C9A39 100%);
          // border: 1px solid #FFFFFF;
          color: #FFFFFF;
          
        }

      }
    }
  }
}

.form-inputs-container {
  padding: 0 20px 0 0;
  box-sizing: border-box;

  > p {
    margin: 5px 0;
    //text-align: center;
    color: #0056A3;
    font-weight: 600;
    font-size: 18px;

  }
  
  @include screen("medium") {
    padding-right: 0px;
.d-flex{
display: block !important;
  .w-50{
width:100% !important;
  }
}
.input-container{
  padding-right: 0px;
}
}
}
.input-container {
  // display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 0px;
  width: 100%;

  > label {
    // position: absolute;
    display: block;
    display: flex;

    .label {}

  }

  .input-field {
    position: relative;

    display: flex;
    .error-msg {
      font-size: 11px;
      line-height: 21px;
      margin-left: 10px;
      color: #fff;
      right: 0;
      bottom: -26px;
      border-radius: 4px;
      background-color: #ff5151;
      padding: 0 6px;
      position: absolute;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        left: 0;
        right: 0;
        margin: auto;
        top: -5px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #ff5151;
      }

    }

    >input {
      // margin-left: 150px;
      width: 100%;
    }

    >.error {
      font-size: 14px;
    }

    >select {
      border: 0.5px solid $input-border;
      background-color: $input-bg; 
      box-sizing: border-box;
      border-radius: 7px;
      height: 40px;
      color: $input-color;
      text-align: start;
      text-align-last: start;

      
      @include screen("medium") {
        width: 100%;
      }
    }
  }

  .input-button-container {
    position: relative;

    input {
      cursor: pointer;
      font-weight: 600;
    }

    .btn-remove {
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 5px;
      right: 9px;
      top: 9px;
      width: 22px;
      height: 22px;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAslJREFUOI2tlU1IVFEYhp/v3KuVUVEQidrCRSpmUGGUUnBnk8SQU6QtCiP6I1pJRkGbVkHEUNGqKMMkIrDFaDN7iYLJIgOp6Q+CMgmKMsoU5s75WpRDM96xH3uX5z3fc777fpx7hAJqatq7yHUzW4BmEVsDUvHD0WGQFNCnmo4lEtc/BdVL/kJra+uciYmSw6ocBeYXOhhAhM/W6umSkvGzPT094wXBzc17yqzNxIA10wED9EhEI7dudb+eAt60aWeF47hJoPwvoZMaBl0Xj3e/BTDw4/Mdx+2dARSgQlVinrd7dhb87dvcI8DqGUABEKF+3jzaAaSpae+ioiL/FXmDqq2toq6umps3E1hrcwDGGFpawgwNPSWVepEP/2xtutIUF/tb86EAdXXVtLVto6PjAMaYHGhHxwHa2raxYkXNlK5VWWBMccRZtmzVCRGm7EilXlJauhjPa2Dp0jKSyYeICO3t+/C8Bu7cGaCz8waqGpSKuiK6PMix1nLu3GUAQqFGRIR0Op2FRqMXyWQyQaWA1rpAaQE3CxcRPK8BgLt37/8GCiDlZhq3UNGfbLIuMAJUBbnGmF8yvY/v+3heA6oHiUYvTNf1iAs8CQJPQkOhxmymqoqqEgo1AhSEi+gTp6pqZQkQyTe3b99MJLKR27eTRKMXsdaiqty7N0h5+RI2bFhLOu3z+PHzgIbllFNZWf/ade1BYHZOSNby7t17Ojtv5FwQVSWZfIjv+wwNPeXDh4/51FHXlUMCEA7vOi4iJwsF9pc6Fo9fPW0AxsbMGVUe/AfowNevch5+/oT6+7smRHQLMDwD6BtjnK39/V0TWTBAPN791hhnLTDwD9BBEV3f13dlZHLB+dV99mzwS1nZqmuzZsmYCGvIG2iARoETmcz8/YnEpZwpFrxG4fCOhcYUR1Q1AtQAPx9ThoGUqvYWFZneWKxrNKj+O4gjF5HzhvjDAAAAAElFTkSuQmCC');
      background-repeat: no-repeat;
      background-position: center;

      transition: all .3 ease;
      @include set-btn-click-animation(0.97);
    }
  }

  &.verified {
    .input-field {
      position: relative;

      .btn-verify {
        position: absolute;

        right: 5px;
        top: 4px;

        height: 32px;
        padding: 0 10px;
        background: #1976ce;
        box-shadow: none;
        border-radius: 6px;
        border: none;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        color: #FFFFFF;
      }

      input {
        // width:calc(100% - 30px);
      }

      .confirm {
        width: 23px;
        height: 23px;
        margin-left: 6px;
        position: absolute;
        right: 10px;
        top: 8px;

        .icon-confirm {
          width: 23px;
          height: 23px;
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAmxJREFUSImt1U1IVFEUwPH/uQ5BEpbTPC0lxI2t8mtctNDKPmhhoSb2sY5qYatMlIiYAinBMloKraOicjIpssg0qDBJRze5GTAKnDHFCRRyfKeFOurMWOPH2b1z7vmdy328+4SVQj3G1d+/TwzlICUIGaJsV+EXyk9Rum2Dd2xPXg/iseMREi+Z1l91VMW+BeSvOHxxF19FaQjkt73+N/6oOsnKCTci1P8fjYJUWgNh10WKWmdi8XcHHJYz9RlwbLXwkhHtwfHxE5R2hQHMQtpKdbasDwbQ45YztTkyCiJn/Gp98GIYMUdGc5+8MajHqMze3CgYwLbtZtRjjGvAtx+kYC1IjVXBg+yrpDm2LS8Iedagr9ghouVrgRt2nOFSWjUA7uTdvAx9jvK13ADF64FfTH6k8/eXmDUKJQbIjC5sMZtpzDhL2da9MU316cvhCyN3COts7A6UTCPgjM4fSinknKuM+1l1nE49uAyuTV8Oz8SD52K7Y/6u2Lk02xnqo29qGHdyDnd31QCQtSl9NTDAmFgDFb0gRdGVlKRkHmZfw52cg7L4KScIg9BrRKUnXi00O8Up/w2Gpv0RuGPyU2IwoMp7Yxu8Ky0IzU5R7b/O9z8BBqf9nB+5nRAMIIpXUMTyVfYC7oS6Eov+YG6e2yCoqF7ZQBiDXkY8tgGYv+jvbQQsSstoXtvbuSHzERyfqAVpXxcMzwPDjrqF5whOaVc4+C2pEqVpTbBKa2B8ooqTjyNvPO4/NN1XddhWuwkoTMAdUlsaxgqedsQMXLFFPcYa9BULWq5QgpIp4FIYQ/ghqt2KeoO53g8IGo/4C6lN9cwPw6KwAAAAAElFTkSuQmCC');
          background-repeat: no-repeat;
          display: block;
        }
      }
    }
  }

}

.radio-group-container {
  display: flex;
  padding: 10px 0 10px 0;

  .radio-input {
    display: flex;
    margin-right: 20px;

    input {
      width: fit-content;
      min-width: 20px !important;
      height: 20px;
      margin-right: 10px;
    }

    label {
      line-height: 20px;
      margin-bottom: 0px;
      cursor: pointer;
    }
  }
}

.check-input {
  display: flex;
  padding: 10px 0 10px 0;

  input {
    width: fit-content;
    min-width: 20px !important;
    height: 20px;
    margin-right: 10px;
  }

  label {
    line-height: 16px;
    margin-bottom: 0px;
    cursor: pointer;
  }
}

.wizard-container {
  margin: auto;
  border: 1px solid $form-border;
  margin-top: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0;
}

.flex-fit-first {
  flex: 1;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  line-height: 18px;
  height: calc(1.5em + 0.75rem + 9px);
  margin: 0;
  overflow: hidden;
  padding: 5px;
  opacity: 1;
  color: $alert-box-shadow-color;
  border: 1px dashed #a4c0d7;
  background-color: #f6fbff;
  font-weight: 600;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Select file";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #dfe8ee;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  /* text-shadow: 1px 1px #fff; */
  font-weight: 700;
  font-size: 10pt;
  background-color: #f3f8fc;
  color: #566673;
  border-radius: 6px;
}
.custom-file-input:hover::before {
  border-color: #9ab0c2;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.common-panel{
    background: $panel-bg;
    box-shadow: $panel-box-shadow;
    color: $panel-text-color;
    border: none;

    .backButton{      
    color: $dark-title-white !important;
    }
    .card{
      background-color: transparent !important;
    } 
      .wrapper{
      border: 1px solid $panel-blue-border-color !important;
    }
    .title{      
      color: $dark-title-white !important;
    }
}
.graphBody {

  .RCP {
    margin: auto;
  }

}
.table{
  
  color: $panel-text-color;
}
.table-bordered td, .table-bordered th {
  color: $panel-text-color;
  border: 1px solid $table-border-color;
}
.table thead th {
  vertical-align: bottom;
  border: 1px solid $table-border-color;
}