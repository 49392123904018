.peoTV {
  border-radius: 10px;
  background: $primary-color-white;
  // margin-top: .6rem;
  box-shadow: 0 0 6px #cee1ed;
  display: grid;
  grid-auto-columns: 1fr 4fr;
  grid-template-areas: 'leftSide rightSide';
  grid-column-gap: 5px;
  min-height: 100%;



  .leftSide {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: leftSide;

    .buttonTab {
      position: relative;
      box-sizing: border-box;
      border-radius: 10px;
      flex-direction: column;
      width: 160px;
      height: 60px;
      margin: 0.8rem 2rem;
      text-align: center;
      text-decoration: none;
      color: #ffffff;
      font-weight: 600;
      z-index: 2;
      // border: 1px solid #deebf3;
      background-color: #275596;
      cursor: pointer;
      display: flex;
      margin-bottom: 0px;

      &.blue {
        background: $primary-color-blue;
        color: $primary-color-white;
      }


      & span {
       position: absolute;
       display: block;
       height: 20px;
       top: 0;
       bottom: 0;
       right: -13px;
       margin: auto;
        color: $primary-color-blue;
      }
      &:last-child{
      margin-bottom:  0.8rem;
      }
    }
  }
  .rightSide {
    border: 1px solid $panel-blue-border-color;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 0.6rem 0.6rem 0.6rem 0;
    grid-area: rightSide;
    min-height: 300px;
    position: relative;
    &.loading {
      min-height: 500px;
    }
    .myPackage {
      display: flex;
      align-items: center;
      height: 100%;

      > div {
        width: 90%;

        .bodyView {
          //width: 367px;
          height: 228px;
          margin: 0.6rem auto;
          background: linear-gradient(180deg, #0056A3 0%, #0B2A58 100%);
          border: 1px solid #C8DBE8;
          box-sizing: border-box;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.09);
          border-radius: 5px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .firstRow {
            font-weight: bold;
            font-size: 16px;
            color: #FFFFFF;
          }

          .secondRow {
            font-weight: bold;
            font-size: 30px;
            color: #FFFFFF;
            margin: 10px;
          }

          .thirdRow {
            display: flex;
            font-weight: normal;
            font-size: 25px;
            color: #FFFFFF;
            justify-content: center;

            > div {
              &.blue {
                color: #00B4EB;
                margin-left: 20px;
              }
            }

          }
          @media screen and (max-width: 992px) {
            margin: 0.6rem 0;
          }
        }
      }

    }

    .vod {
      margin: 0.8rem auto;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;

      > div {
        margin: 0.6rem;

        .imageView {
          max-width: 168px;
          border-radius: 7px 7px 0 0;
        }

        .title {
          width: 100%;
          max-width: 168px;
          background: $primary-bg;
          border-radius: 0 0 7px 7px;
          font-size: 14px;
          color: #565656;
          font-weight: bold;
          padding: 5px;
        }
      }


    }

    .oneVod {
      margin-bottom: 0.6rem;

      .title {
        display: flex;
        @include subBodyBackgroundTitle();

        &.gray {
          color: #6d7c8f;
        }

        > span {
          cursor: pointer;
          //margin: 0.3rem 0 0.6rem 0.6rem;
        }

      }

      .upperBody {
        margin: 0.6rem 2rem 0.6rem 2rem;
        display: flex;
        width: 96%;


        > img {
          width: 379px;
          height: 239px;
        }

        > div {
          float: right;
          margin: 2rem;
          font-weight: 600;
          font-size: 20px;
          color: $primary-color-white;
        }
      }

      .lowerBody {
        width: 95%;
        margin: auto;
        border: 1px solid $panel-blue-border-color;
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: normal;
        font-size: 16px;
        color: $primary-color-white;

        .movie {
          padding: 10px;
          border-bottom: 1px solid $panel-blue-border-color;
        }

        .description {
          padding: 10px;
        }
      }
    }

    .peoTVPackages {
      margin: 0.8rem auto;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;

      > div {
        margin: 0.6rem;

      }

    }

    .channelList {
      .switch-button {
        background: #C4C4C4;
        border-radius: 10px;
        overflow: hidden;
        width: 263px;
        line-height: 26px;
        height: 36px;
        text-align: center;
        font-size: 13px;
        letter-spacing: 1px;
        position: relative;
        padding-right: 110px;


        &:before {
          content: "ALA-CARTE";
          font-size: 13px;
          color: $primary-color-white;
          font-weight: bold;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 141px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 3;
          pointer-events: none;
        }

        &-checkbox {
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 2;

          &:checked + .switch-button-label:before {
            transform: translateX(130px);
            transition: transform 300ms linear;
          }

          & + .switch-button-label {
            position: relative;
            padding: 5px 0;
            display: block;
            user-select: none;
            pointer-events: none;

            &:before {
              content: "";
              background: $primary-color-blue;
              height: 100%;
              width: 95%;
              position: absolute;
              left: 0;
              top: 0;
              border-radius: 8px;
              transform: translateX(0);
              transition: transform 300ms;
            }

            .switch-button-label-span {
              position: relative;
            }
          }
        }
      }

      .images {
        margin: 0.8rem auto;
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;

        > img {
          margin: 0.8rem;
        }

        .details {
          margin: 0.8rem;
          background: #FFFFFF;
          border-radius: 8px;
          .tag {
            position: absolute;
            background: $main-btn-bg;
            color: $input-text-color;
            border-radius: 5px;
            padding: 0 4px 0 4px;
            font-weight: bold;
          }
          > img {
            margin: 0.4rem;
          }

          .title {
            width: 100%;
            max-width: 168px;
            background: $main-btn-bg;
            border-radius: 0 0 7px 7px;
            font-size: 16px;
            color: $input-text-color;
            font-weight: bold;
            padding: 5px 20px;
          }
        }
      }
    }

    .peoTvGo {
      min-height: 500px;
      display: flex;
      justify-content: center;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > img {
          max-width: 308px;
          height: 308px;
        }
      }
    }

    .noPeoTvAccount{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      color: $dark-title-white;
      padding: 0.6rem;

    }

  }
  @media screen and (max-width: 992px) {

    grid-template-areas: "leftSide"
      "rightSide";
    grid-auto-columns: initial;
    grid-template-columns: initial;
    //width: 100%;
    .leftSide {
      flex-direction: row;
      justify-content: space-between;
      width: auto;
      .buttonTab {
        margin: 0.8rem auto;
        width: 20%;
        & span {
          display: none;
        }
      }
    }
    .rightSide {
      margin: 0.6rem;
    }
  }


}
