.newFTTHLandingPage {
  height: 100%;
  nav {
    background: linear-gradient(90deg, #0f55a7 0%, #4db848 100%);

    padding: 10px;
    border-bottom: 1px solid linear-gradient(90deg, #0f55a7 0%, #4db848 100%);

    .right-options-container {
      display: flex;
      flex-direction: row;
      position: relative;
      align-items: center;

      .portal-label {
        font-size: 17px;
        font-weight: bold !important;
        color: $primary-color-white;
        margin: 10px 10px;
        line-height: 31px;
        @include screen("small") {
          display: none;
        }
      }

      .number-select {
        font-size: 15px;
        font-weight: bold !important;
        margin: 10px 12px;
        margin-left: 0px;
      }

      .cart {
        margin-left: 1em;
      }

      .moreSelect {
        position: absolute;
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        min-width: 200px;
        right: 0;
        margin-top: 210px; // change 64 to 278 because error in 13/08/2021 312

        &.all {
          margin-top: 278px;
        }

        .userdetails {
          display: flex;
          flex-direction: row;
          border-top: 1px solid $dropdown-border;
          border-bottom: 1px solid $dropdown-border;
          padding: 10px;
          color: $dropdown-text-color-white;

          > div {
            .user {
              width: 40px;
              height: 40px;
              margin-right: 8px;
            }
          }

          .info {
            text-align: left;
            font-size: 14px;

            .my-slt {
              font-weight: bold;
            }

            .number {
              font-weight: bold;
            }
          }
        }

        > div {
          background: $dropdown-bg;
          box-shadow: 0 7px 6px #0a2d5e36;
          // padding: .5rem;
          z-index: 30;
          margin-right: 0;
          font-size: 20px;
          border-radius: 0 0 10px 10px;

          .items {
            // margin-top: 1rem;

            .item {
              margin: 0.2rem;
              cursor: pointer;
              padding: 4px 10px;
              font-size: 15px;
              color: $dropdown-text-color;
              border-radius: 6px;
              transition: all 100ms linear;

              &:hover {
                background-color: #d5e6f2;
                color: #253a47;
              }

              > strong {
                color: #4f8a10;
                background-color: #dff2bf;
                border-radius: 3px;
                font-weight: 900;
                padding: 1px 5px;
              }
            }
          }
        }
      }
    }

    .logo {
      width: 138px;
      &:hover {
        cursor: pointer;
      }
    }

    .nav-container {
      margin: auto;
      max-width: 1200px;
    }

    & img {
      &.user {
        width: 50px;
        height: 50px;
        cursor: pointer;
        border-radius: 50%;
      }
    }

    .ftthLabel {
      position: relative;
      right: 12px;
      top: -11px;
      color: #ffffff;
      background-color: #ff5a5a;
      border-radius: 50%;
      font-weight: 900;
      padding: 0 6px;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
    }
    .footer-container {
      margin: auto;
      max-width: 850px;
      min-height: 52px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icons {
        width: 100%;
        text-align: end;
        display: flex;
        justify-content: end;
        gap: 12px;
        img {
          width: 24px;
          .youtube {
            width: 30px;
          }
        }
      }
       // Mobile responsiveness
        @media screen and (max-width: 768px) {
        flex-direction: column;

        .copyrights {
          margin-bottom: 20px; // Adjust spacing between sections
          text-align: center;

          img {
            margin-right: 0; // Remove right margin for image
          }
        }

        .icons {
          margin-top: 20px; // Adjust spacing between sections
          justify-content: center;
        }
      }
      
      .copyrights {
        min-width: 240px;
        color: #fff;
        text-align: center;
        font-size: 11.8px;
        font-weight: 400;
        vertical-align: baseline;
        img {
          width: 12px;
          margin-right: 5px;
        }
      }
    }
  }
}
.landingFTTHPage {
  display: grid;
  min-height: calc(100vh - 186px);
  justify-content: center;
  align-items: center;
  grid-template-areas: "bannerView publicViews";
  margin: 20px 0;

  @include customAlertViewSign("O");

  .title {
    @include subBodyBackgroundTitle();
  }

  @include TermCondistion(-138px, -30px);

  .banner {
    grid-area: bannerView;

    & img.banner {
      max-width: 400px;
      min-width: 400px;
      width: auto;
      height: 610px;
      border-radius: 12px 0 0 12px;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  userInformation {
  width: 100%;
  height: 100%;
  //min-height: 540px;
  margin: auto;
  position: relative;

  .form-container {
    .form-inputs-container {
      width: 50%;
      float: left;
      @include screen("medium") {
        width: 100%;
        padding-right: 0px;
      }
    }

    input {
      background-color: white;
      color: black;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #363636;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #363636;
      }
      &:-webkit-autofill {
        -webkit-text-fill-color: #363636 !important;
      }
    }
    select {
      background-color: white !important;
      color: black !important;
      option {
        color: black !important;
      }
    }
    label {
      color: #363636;
    }

    .autoCom {
      width: 100%;

      .MuiFormControl-root {
        .MuiInputBase-root {
          padding-right: 39px;
          height: 40px;
          font-size: 14px;
          color: $input-color;
          input {
            padding: 0px;
          }

          fieldset {
            border-color: $input-border;
            border-radius: 7px;
          }

          button {
            position: absolute;
            right: -3px;
            top: -2px;
            height: 32px;
            padding: 0 10px;
            background: #8a99a6;
            box-shadow: none;
            border-radius: 6px;
            border: none;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            text-align: center;
            color: #ffffff;
            width: 30px;
          }
        }
      }
    }
  }

  .userInfoForm {
    width: 100%;
    margin: auto;
    // border: 1px solid #ececec;
    /* box-shadow: 0 0 1px #e2e2e2; */
    box-sizing: border-box;
    border-radius: 10px;
    padding: 2rem;
    @include screen("medium") {
      padding: 1rem;
    }

    .packages-container {
      display: flex;
      flex: 1;
      gap: 20px;
      margin-bottom: 20px;
      @include screen("medium") {
        flex-direction: column;
      }
      .package-container {
        border-radius: 12px;
        background: #f9f9f9;
        width: 50%;
        @include screen("medium") {
          width: 100%;
        }
        // font-family: Poppins;
        padding: 15px;
        .mini-title {
          color: #787878;
          font-size: 12px;
          font-weight: 400;
        }
        .main-title {
          color: #363636;
          font-size: 24px;
          font-weight: 600;
        }
        .details-title {
          color: #007eff;
          font-size: 12px;
          font-weight: 400;
        }
        .package-details {
          color: #363636;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }

  .otpInput {
    position: absolute;
    width: 100%;
    height: 100%;
    //min-height: 550px;
    background: rgba(102, 107, 134, 0.1);
    mix-blend-mode: normal;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;

    .otpInputBody {
      max-width: 600px;
      max-height: 600px;
      min-width: 500px;
      background: $panel-blue-bg;
      border-radius: 8px;
      //padding: 2rem;

      .title {
        @include subBodyBackgroundTitle();
        > span {
          float: right;
          margin: 10px;
          &:hover {
            cursor: pointer;
          }
        }
      }

      .inputArea {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 150px;
        padding: 0;

        input {
          padding-left: 4px;
        }
      }

      .resend {
        color: $dark-title-white;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .container-footer {
    border-top: 0px;
  }
}

.landingFTHOuter {
  width: 100%;
  height: 610px;
  //min-height: 540px;
  margin: auto;
  position: relative;

  .form-container {
    .form-inputs-container {
      width: 50%;
      float: left;
      @include screen("medium") {
        width: 100%;
        padding-right: 0px;
      }
    }

    input {
      background-color: white;
      color: black;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #363636;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #363636;
      }
      &:-webkit-autofill {
        -webkit-text-fill-color: #363636 !important;
      }
    }
    select {
      background-color: white !important;
      color: black !important;
      option {
        color: black !important;
      }
    }
    label {
      color: #363636;
    }

    .autoCom {
      width: 100%;

      .MuiFormControl-root {
        .MuiInputBase-root {
          padding-right: 39px;
          height: 40px;
          font-size: 14px;
          color: $input-color;
          input {
            padding: 0px;
          }

          fieldset {
            border-color: $input-border;
            border-radius: 7px;
          }

          button {
            position: absolute;
            right: -3px;
            top: -2px;
            height: 32px;
            padding: 0 10px;
            background: #8a99a6;
            box-shadow: none;
            border-radius: 6px;
            border: none;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            text-align: center;
            color: #ffffff;
            width: 30px;
          }
        }
      }
    }
  }

  .userInfoForm {
    width: 100%;
    margin: auto;
    // border: 1px solid #ececec;
    /* box-shadow: 0 0 1px #e2e2e2; */
    box-sizing: border-box;
    border-radius: 10px;
    padding: 2rem;
    @include screen("medium") {
      padding: 1rem;
    }

    .packages-container {
      display: flex;
      flex: 1;
      gap: 20px;
      margin-bottom: 20px;
      @include screen("medium") {
        flex-direction: column;
      }
      .package-container {
        border-radius: 12px;
        background: #f9f9f9;
        width: 50%;
        @include screen("medium") {
          width: 100%;
        }
        // font-family: Poppins;
        padding: 15px;
        .mini-title {
          color: #787878;
          font-size: 12px;
          font-weight: 400;
        }
        .main-title {
          color: #363636;
          font-size: 24px;
          font-weight: 600;
        }
        .details-title {
          color: #007eff;
          font-size: 12px;
          font-weight: 400;
        }
        .package-details {
          color: #363636;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }

  .otpInput {
    position: absolute;
    width: 100%;
    height: 100%;
    //min-height: 550px;
    background: rgba(102, 107, 134, 0.1);
    mix-blend-mode: normal;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;

    .otpInputBody {
      max-width: 600px;
      max-height: 600px;
      min-width: 500px;
      background: $panel-blue-bg;
      border-radius: 8px;
      //padding: 2rem;

      .title {
        @include subBodyBackgroundTitle();
        > span {
          float: right;
          margin: 10px;
          &:hover {
            cursor: pointer;
          }
        }
      }

      .inputArea {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 150px;
        padding: 0;

        input {
          padding-left: 4px;
        }
      }

      .resend {
        color: $dark-title-white;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .container-footer {
    border-top: 0px;
  }
}

  .formView {
    grid-area: publicViews;
    position: relative;
    height: 610px;
    min-width: 500px;
    max-width: 550px;
    padding: 40px;
    border-radius: 0px 20px 20px 0px;
    background: #fff;
    box-shadow: 0px 4px 20px 7px rgba(0, 0, 0, 0.06);

    &.alertBackground {
      display: none;
      /* Hidden by default */
      position: fixed;
      /* Stay in place */
      z-index: 1;
      /* Sit on top */
      padding-top: 100px;
      /* Location of the box */
      left: 0;
      top: 0;
      width: 100%;
      /* Full width */
      height: 100%;
      /* Full height */
      overflow: auto;
      /* Enable scroll if needed */
      background-color: rgb(0, 0, 0);
      /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4);
      /* Black w/ opacity */
    }

    & div {
      &.form {
        // width: 355px;
        @include screen("small") {
          width: 100%;
        }
      }
    }

    .logo-container {
      margin-bottom: 40px;

      .logo {
        width: 184px;
      }
    }

    & img {
      &.icons {
        width: 25px;
        height: 25px;
        position: absolute;
        margin: 8px;
      }

      &.eye {
        width: 18px;
        position: absolute;
        float: right;
        cursor: pointer;
        right: 14px;
        bottom: 12px;

        @media only screen and (max-width: 600px) {
          margin-left: 250px;
        }

        @media only screen and (max-width: 300px) {
          margin-left: 230px;
        }
      }
    }

    .btn-back {
      text-decoration: none;
      color: #8f8f8f;
      font-size: 13px;
      position: absolute;
      bottom: 40px;

      .back {
        display: flex;
        align-items: center;
        color: #8f8f8f;

        img {
          width: 22px;
          height: 22px;
          margin-right: 6px;
        }

        p {
          margin: 0px;
          color: $link-btn-color;

          transition: all 200ms ease-in-out;

          &:hover {
            color: $link-btn-color-hover;
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      border-radius: 20px;
      padding: 20px;
      min-width: 388px;
    }
    @media only screen and (max-width: 390px) {
      margin: 2px;
      min-width: 280px;
    }
  }

  .new-ftth-langing-form {
    .titles {
      text-align: center;
      color: #434343;
      text-align: center;
      // font-family: Poppins;
      font-size: 27px;
      font-style: normal;
      font-weight: 500;
      line-height: 35px;
    }

    .connectionContainer {
      .titles {
        // font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        text-align: left;
        color: #434343;
      }

      .connectionTypeContainer {
        display: flex;
        width: 120px;
        height: 66px;
        border-radius: 7px;
        border: 0.5px solid #bcd;
        background: linear-gradient(
          270deg,
          #4db74a 0.33%,
          #287c82 61.02%,
          #1057a6 99.58%
        );
        align-items: center;
        // font-family: Poppins;
        font-size: 17px;
        font-weight: 600;
        justify-content: center;
        a {
          color: #ffffff;
          text-decoration: none;
        }
      }

      .line {
        border-bottom: 0.5px solid #51b848;

        span {
          font-size: 14px;
          padding-right: 10px;
          background-color: white;
        }
        .or {
          position: absolute;
          left: 0;
          right: 0;
          display: block;
          width: fit-content;
          margin: auto;
          padding: 0 10px;
          color: #565656;
          font-family: "Open Sans";
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
        }
      }
    }

    .connectionStatusCkeckContainer {
      margin: 5px;
      > fieldset {
        input {
          color: #565656;
          background-color: white;

          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #a8cbff;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #a8cbff;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #a8cbff;
          }
        }

        /*Change text in autofill textbox*/
        input:-webkit-autofill {
          -webkit-text-fill-color: $dark-text-white !important;
        }
      }
    }

    .payDetails {
      margin-left: 60px;
      margin-right: 60px;
      position: absolute;
      bottom: 0;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .text {
        color: #656565;
        // font-family: Poppins;
        font-size: 10.8px;
        font-style: normal;
        font-weight: 400;
      }
      .icons {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-left: 20px;
        .payHere {
          background-repeat: no-repeat;
          width: 59px;
          height: 17px;
          background-position: center;
          background-size: 100%;
          background-image: url("../../../images/new_ftth/payHere.png");
        }
        .visa {
          background-repeat: no-repeat;
          width: 37px;
          height: 11.217px;
          background-position: center;
          background-size: 100%;
          background-image: url("../../../images/new_ftth/visa.png");
        }
        .masterCard {
          background-repeat: no-repeat;
          width: 25.858px;
          height: 15.448px;
          background-position: center;
          background-size: 100%;
          background-image: url("../../../images/new_ftth/masterCard.png");
        }
      }
      // Mobile responsiveness
      @media screen and (max-width: 768px) {
        flex-direction: column;
    
        .text {
          margin-bottom: 10px; // Adjust spacing between text and link
          text-align: center;
        }
    
        .icons {
          flex-direction: row; // Align icons horizontally
          justify-content: center; // Center icons horizontally
          margin-bottom: 10px; // Adjust spacing between icons and link
        }
      }
    }
  }

  .mobileResponsive {
    width: 1000px;
  
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  

  .fixedConnectionStatusTracking {
    background: #ffffff;
    border-radius: 16px;
    padding: 10px;

    .header {
      margin: 10px;
      display: flex;
      .titles {
        flex: 1;
        padding: 5px;
        margin: 5px;
        // font-family: Poppins;
        font-size: 27px;
        font-weight: 500;
        line-height: 41px;
        letter-spacing: 0em;
        text-align: left;
        color: #434343;
      }

      .sub-titles {
        flex: 1;
        padding: 5px;
        background: #e8f3fc;
        border-radius: 5px, 5px, 0px, 0px;

        color: #0056a3;
        // font-family: Poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .middle-container {
      background: #f9f9f9;
      border-radius: 12px;
      margin: 5px;

      .order-details {
        margin: 5px;
        color: #787878;

        // font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;

        .details {
          display: flex;
          text-align: center;
          align-items: center;
        }
      }

      .order-status {
        margin: 5px;
        // font-family: Poppins;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        .valName {
          color: #434343;
        }
        .value {
          color: #0056a3;
        }
      }

      .package-container {
        flex: 1;
        background: #f9f9f9;
        margin: 10px;
        padding: 10px;
        border-radius: 12px;
        .mini-title {
          color: #787878;
          // font-family: Poppins;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
        }
        .sub-title {
          color: #0056a3;
          // font-family: Poppins;
          font-size: 15px;
          font-weight: 600;
          line-height: 23px;
          letter-spacing: 0em;
          text-align: center;
        }
        .main-title {
          color: #363636;
          // font-family: Poppins;
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: 0em;
          text-align: left;
        }
        .details-title {
          > p {
            margin: 0;
            color: #007eff;
            // font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: center;
          }
        }
        .package-details {
          border: 1px solid #717171;
          // font-family: Poppins;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }

    .formUpload {
      background: #f9f9f9;
      margin: 10px;
      padding: 5px;
      border-radius: 12px;

      .header {
        color: #363636;
        // font-family: Poppins;
        text-align: left;
        margin: 5px;
        padding: 5px;
      }

      .uploadField {
        display: flex;
        margin: 10px;
        > div {
          flex: 1;
          margin: 5px;

          > label {
            color: #0056a3;
          }
        }
      }

      .footer {
        display: flex;
        padding: 10px 0;
        align-items: center;
        margin-top: 30px;

        .file-requrements {
          display: flex;

          .requirement {
            background-color: #fff8ca;
            padding: 4px 10px;
            border: 1px solid #f4ebaf;
            border-radius: 7px;
            font-size: 14px;
            color: #ae8900;
            margin-right: 10px;
          }
        }

        .button-container {
          width: 100%;
          display: flex;
          margin-left: auto;
          flex: 1 1;

          button {
            margin-left: auto;
            padding: 8px 14px;
          }
        }
      }
      @media screen and (max-width: 768px) {
        .header {
            flex-direction: column;
            align-items: center;
            text-align: center;
            .titles {
                font-size: 6px;
                line-height: 1px;
            }
            .sub-titles {
                font-size:  6px;
                line-height: 1px;
            }
        }

        .middle-container {
            .order-details {
                font-size:  6px;
                line-height: 1px;
            }
            .order-status {
                font-size:  6px;
                line-height: 1px;
            }
            .package-container {
                .mini-title {
                    font-size: 6px;
                    line-height: 1px;
                }
                .sub-title {
                    font-size: 6px;
                    line-height: 1px;
                }
                .main-title {
                    font-size: 6px;
                    line-height: 1px;
                }
                .details-title {
                    > p {
                        font-size: 6px;
                        line-height: 1px;
                    }
                }
                .package-details {
                    font-size: 6px;
                    line-height: 1px;
                }
            }
        }

        .formUpload {
            .header {
                font-size: 6px;
                line-height: 1px;
            }

            .uploadField > div {
                margin: 5px;
                label {
                    font-size: 6px;
                }
            }
            .footer {
                .file-requrements {
                    .requirement {
                        font-size: 6px;
                    }
                }
            }
        }
      }
    }
  }
}

.title {
  color: #434343;
  // font-family: Poppins;
  font-size: 27px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.map-container {
  //width: 50%;
  padding-bottom: 25px;
  height: 380px;
}
