.noAnyAccount {
  &.sentence {
    width: 450px;
    height: 80px;
    margin: auto;
    background: #0F3B7A;
    color: $primary-color-white;
    @include box-border(10px)
  }

  &.buttonSet {
    margin: 50px 5px 0 5px;
  }
}

.boardBandNav {
  display: flex;
  height: auto;
  margin: auto;
  // margin-top: 0.6rem;
  .buttonBB-wrapper {
    display: flex;
    flex: 1;

    &:first-child {

      .buttonBB {
        border-radius: 8px 0 0 8px;
        border-left: 1px solid $panel-blue-border-color;
      }

    }

    &:last-child {

      .buttonBB {
        border-radius: 0 8px 8px 0;
        // border-left: 1px solid #C8DBE8;
      }

    }

  }

  @include screen("small") {
    flex-wrap: wrap;
    margin: 0 -4px;
    .buttonBB-wrapper {
      padding: 2px 2px;
      flex: 0 0 33.33%;

      .buttonBB {
        width: 100%;
        border-radius: 8px !important;
        border-left: 1px solid $panel-blue-border-color;
      }
    }
  }

  .buttonBB {
    background: $panel-bg;
    text-align: center;
    display: block;
    width: 100%;
    margin: auto;
    text-decoration: none;
    border-radius: 0;
    padding: 4px;
    line-height: 0;
    font-size: 13px;
    font-weight: 600;
    color: $primary-color-white;
    box-shadow: none;
    border: 1px solid $panel-blue-border-color;
    border-left: none;
    transition: all 100ms linear;
    position: relative;

    &.colored {
      > div {
        transition: all 150ms linear;
        background-color: #0082d1;
        border-radius: 4px;
        color: $primary-color-white;
      }
    }

    > div {
      height: 28px;
      line-height: 28px;


      > svg {
        position: absolute;
        right: 8px;
        top: 15px;
      }
    }



  }

  .moreBb {
    position: absolute;
    z-index: 10;
    top: 38px;
    right: -1px;

    @include screen('small'){
      right: initial;
      left: 0;
    }

    > div {
      background: $panel-bg;
      text-align: center;
      display: block;
      width: 100%;
      margin: auto;
      text-decoration: none;
      border-radius: 0 !important;
      padding: 15px 5px;
      line-height: 0;
      font-size: 13px;
      font-weight: 600;
      color: $primary-color-white;
      box-shadow: none;
      border: 1px solid $panel-blue-border-color;
      transition: all 100ms linear;
      min-width: 200px;

      @include screen('small'){
        border-radius: 0 !important;
      }

      &:hover {
        cursor: pointer;
      }

      &:first-child {
        border-bottom: none;
      }

      &:last-child {
        border-top: none;
      }
      &.colored {
          transition: all 150ms linear;
          background-color: #0082d1;
          border-radius: 4px;
          color: $primary-color-white;
      }
    }
  }

  & select {
    background: $panel-bg;
    flex-direction: row;
    width: 100%;
    color: $primary-color-white;
    margin: auto;
    font-size: 14px;
    padding: 5px 0;
    height: 38px;
    line-height: 50px;
    box-shadow: none;
    border: 1px solid $panel-blue-border-color;
    border-left: none;
    border-radius: 0 8px 8px 0;

    > option {
      &.colored {
        background: #0056A3;
      }
    }
  }
}

.package-functions {
  margin-top: .6rem;

  .function-box-wrapper {
    width: 50%;
    float: left;
    padding-right: .6rem;


    &:last-child {
      padding-right: 0;
    }

    @include screen("medium") {
      width: 100%;
      padding-right: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .function-box {
      border-radius: 10px;
      background: $panel-bg;
      box-shadow: $panel-box-shadow;
      padding: 10px;
      color: $primary-color-white;


      .pkg-details {
        width: 60%;
        float: left;

        .detail-row {
          margin: 0;
          font-size: 14px;
          color: $primary-color-white;
          font-weight: 600;

          .label {
          }

          .value {

            font-weight: 600;
            font-size: 14px;
            color: $primary-color-white;
          }
        }
      }

      .pkg-details-btn-container {
        width: 40%;
        float: left;
        height: 66px;
        position: relative;

        button {
          position: absolute;
          box-shadow: none;
          height: 100%;

          &.white {
            background: $button-bg;
            color: $button-color; 
            border: $button-border;

          }
        }
      }

      .button-container-50 {
        width: 50%;
        float: left;
        height: 66px;
        position: relative;
        padding-right: 10px;
        box-sizing: border-box;

        &:last-child {
          padding-right: 0;
        }

        button {
          box-shadow: none;
          height: 100%;

          &.white {
            background: $button-bg;
            color: $button-color; 
            border: $button-border;
          }
        }
      }
    }
  }
}

.boardBandSummary {
  width: 100%;
  min-height: 430px;
  // @include box-border(10px);
  border-radius: 10px;
  background: $panel-bg;
  box-shadow: $panel-box-shadow;
  margin-top: .6rem;
  display: grid;
  grid-auto-columns: 0fr 3fr;
  grid-template-areas: "navSide graphbody";

  .summary-container {
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
    margin: 0.6rem;
    a{
    display: block;
        margin-bottom: 10px;

        .buttonTab{
          background-color: #275596;
        }
  }
    .inputIcon {
      float: right;
      margin-right: -10px;
      margin-top: -47px;
      position: relative;
      z-index: 2;
      color: $primary-color-blue;
    }

  }

  @media screen and (max-width: 992px) {

    grid-template-areas: "navSide"
      "graphbody";

    grid-auto-columns: initial;

    .summary-container {
      display: flex;
      flex-flow: row;
      justify-content: space-between;

      a {

        display: block;
        flex-basis: 33%;
        padding: 5px;

        // width: 20%;
        .buttonTab {
          margin: 0;
          width: -moz-fit-content;
          width: 100%;
          /* height: fit-content; */
          padding: 0 6px;
          box-sizing: border-box;
          display: flex;

          .pkg-option {
            display: none;
          }

          div {
            margin: auto;
            font-size: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .inputIcon {
          display: none;
        }

      }

      padding: 5px;
      padding-bottom: 0;
    }
  }


  @media only screen and (max-width: 576px) {

    grid-template-areas: "navSide"
      "graphbody";


    .summary-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      a {

        display: block;
        flex-basis: 33%;
        padding: 5px;
        // width: 20%;
        .buttonTab {
          margin: 0;
          width: -moz-fit-content;
          width: 100%;
          /* height: fit-content; */
          padding: 0 6px;
          box-sizing: border-box;

          .pkg-option {
            display: none;
          }

          div {
            margin: auto;
            font-size: 15px;
            margin-top: 17px !important;
          }
        }

        .inputIcon {
          display: none;
        }

        &:nth-child(4) {

          flex: 0 0 50%;
        }

        &:nth-child(5) {

          flex: 0 0 50%;
        }
      }

      padding: 5px;
      padding-bottom: 0;
    }
  }

  &.navSide {
    grid-area: navSide;
    display: flex;
  }

}

.graphBody-wrapper {

  padding: 0.8rem 0.8rem 0.8rem 0;
  @include screen("large") {
    padding: 0.8rem;
  }

  .graphBody {
    grid-area: graphbody;
    border: 1px solid $panel-blue-border-color;
    box-sizing: border-box;
    border-radius: 8px;
    width: auto;
    height: auto;
    min-height: 416px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: relative;
    color: $panel-text-color; 
    @include screen("large") {
      // margin: 0.6rem 0.6rem 0.6rem 0.6rem;
    }

    &.noPadding {
      .title {
        @include subBodyBackgroundTitle();
      }

      //padding: 0;
      @include customAlertView();
    }

    @include screen("large") {
      .carousel {
        width: 100% !important;

        .slider-wrapper {
          width: 100% !important;

          .slider {
            -ms-box-orient: horizontal;
            display: -moz-flex;
            display: flex;
            width: 240px;
          }
        }
      }

    }

    .carousel .control-dots {
      bottom: 20px;
    }


    .carousel .thumbs-wrapper {
      display: none;
    }

    .graph-body-title {
      text-align: center;
      font-size: 18px;
      margin-top: 10px;

      .speed {
        color: $primary-color-white;
        background-color: #0274f4;
        padding: 6px 10px;
        border-radius: 25px;
        font-size: 14px;
        line-height: 10px;
        margin: 0 2px;
      }
    }

    .progress-bar-container {
      margin-bottom: 10px;

      .RCP {
        margin: auto;
      }

    }

    .used-of {
      font-size: 20px;
      font-weight: 600;
    }

    .name {
      font-size: 18px;
    }

    .unlimited {
      margin: 60px 0 60px 0;

      .unlimitedUsage {
        font-size: 36px;
        font-weight: 800;
        color: #7C4DFF;

      }
    }

    .upgradeButton {

      .btn-upgrade {
        padding: 10px 18px !important;
        background: linear-gradient(180deg, #15d151 0%, #0db041 100%);
        padding: 17px;
        color: $primary-color-white;
        box-shadow: none;
        border: none;

      }
    }
  }
}

.buttonTab {
  box-sizing: border-box;
  border-radius: 10px;
  flex-direction: column;
  width: 160px;
  height: 60px;
  // margin: 0.8rem 0.8rem 0.8rem;
  text-align: center;
  display: flex;
  text-decoration: none;
  color: $primary-color-white;
  font-weight: 600;
  z-index: 2;
  border: $button-border;
  background-color: $button-bg;

  .pkg-option {
    font-size: 12px;
    margin-bottom: 0px;
    color: $primary-color-white;
  }

  &.blue {
    background: $primary-color-blue;
    color: $primary-color-white;

    .pkg-option {
      color: $primary-color-white;
    }
  }

  & div {
    margin: auto;
    font-size: 15px;
  }

  & span {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
  }
}

.indicator {
  //display: flex;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 35%;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  .progress-count {
    font-weight: 900;
    font-size: 36px;
    margin-bottom: -10px;
    color: $primary-color-white;
    cursor: pointer;
  }

  .label {
    font-size: 14px;
    font-weight: 900;
    color: $primary-color-white;
  }
}

//.inputIcon {
//  float: right;
//  margin-right: 1px;
//  margin-top: -57px;
//  position: relative;
//  z-index: 2;
//  color: $primary-color-blue;
//}
