.happyday {
    .title_happyday {
      > div {
        max-width: 500px;
        margin: auto;

        .highlight {
          color: #FFB917;
          display: inline;
        }

        .date_text {
          color: #9AC7CD
        }
      }
      width: auto;
      text-align: center;
      font-weight: 700;
      font-size: 20px;

    }

    .date_select {
      max-width: 650px;
      width: auto;
      margin: auto;
      margin-top: 60px;
      display: flex;
      flex: 3 1;
      align-items: center;
      justify-content: center;
      column-gap: 15px;

      .date_input {
        text-align: center;
        font-weight: 700;
        font-size: 14px;
      }
     
    }

    .terms {
      max-width: 500px;
      margin: auto;
      margin-top: 40px;
      margin-bottom: 40px;
      font-size: 14px;

      .head {
        font-size: 13px;
        font-weight: bold;
      }

      > div {
        margin-top: 10px;
        display: flex;
        flex: auto;
        gap: 5px;
      }
    }
}