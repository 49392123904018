.packageInformation {
  width: 100%;
  height: 100%;
  margin: auto;
  // position: relative;

  .packageSelect {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(102, 107, 134, 0.1);
    mix-blend-mode: normal;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 10px;
    z-index: 5;

    .peoTvPack {
      width: calc(100% - 40px);
      max-height: 500px;
      // background: #FFFFFF;
      background: #3a65a4;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: 0 0 10px rgb(0 0 0 / 11%);
      opacity: 0;

      transform: scale(0.98);

      transition: all 100ms ease-in-out;

      @include screen("medium") {
        top: 3%;
        position: absolute;
        max-height: fit-content;
      }
      &.show {
        opacity: 1;
        transform: scale(1);
      }

      .peoTVPackages {
        margin: 0.8rem auto;
        position: relative;
        justify-content: space-evenly;
        max-height: 450px;
        overflow: auto;
        @include screen("small") {
          max-height: 700px;
        }

        .package-item-wrapper {
          width: 20%;
          float: left;
          padding: 10px;
          box-sizing: border-box;

          @include screen("medium") {
            width: 100%;
          }
          .package-item {
            border-radius: 15px;
            min-height: 200px;
            padding: 20px;
            color: #ffffff;
            position: relative;
            cursor: pointer;
            background: linear-gradient(
              0deg,
              rgba(256, 256, 256, 0.24) 0%,
              rgba(34, 193, 195, 0) 100%
            );

            .count {
              display: flex;

              .number {
                font-size: 16px;
                font-weight: 600;
                width: 40px;
                height: 40px;
                background-color: rgba(255, 255, 255, 0.815);
                border-radius: 40px;
                text-align: center;
                line-height: 40px;
                margin-right: 6px;
              }

              .name {
                font-size: 16px;
                line-height: 40px;
              }
            }

            .package-name {
              font-size: 20px;
              font-weight: 600;
              margin-top: 10px;
            }

            .price {
              font-size: 18px;
              position: absolute;
              bottom: 20px;
            }

            transition: all 150ms ease-in-out;

            &:hover {
              transform: scale(1.02);
            }

            &::after {
              content: "";
              width: 36px;
              height: 36px;
              position: absolute;
              border-radius: 36px;
              background-color: #08ac31;
              background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAALCAYAAACgR9dcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAKlJREFUKJGV0DuKQkEQBVBdhDirUDGSQTA2kQlFZAIzd2FkaOAaBFfiAkRwC4P4CTQZv8fAFpsH8nyVNHTVuVCVy2UsNLDCNCus4+BRqyzwG/sA/9H8FFaxC/CI1rPRxwRfb2AF2wBP+Imbzx2WKCZgGZvQv6CdTB541QKF8F/COoKddzsNo4A5aviLYDftKKMo4BzeK34/uWge4yjghl4qTATMAhynzd8BBJsBlakQ16kAAAAASUVORK5CYII=");
              background-repeat: no-repeat;
              background-position: center;
              right: 20px;
              bottom: 20px;
              opacity: 0;
              transform: scale(0.55) !important;
              transition: all 100ms ease-in-out;
              border: 3px solid #ffffff;
            }

            &:hover {
              // border-color: #c98ee7; */
              background-color: #f3faff;
              border-color: #f3faff;
              transform: scale(1.02);
              transition: all 150ms ease-in-out;
            }

            &.selected {
              // border: 2px solid #07ac31;
              // background-color: #f6fbf7;
              background-color: #005ea8;
              border-color: #2984c5;
              box-sizing: border-box;
              border-radius: 14px;
              transform: scale(1.05) !important;

              position: relative;

              &::after {
                opacity: 1;
                transform: scale(1) !important;
              }
            }
          }
        }
      }
    }

    .broadbandPack {
      width: calc(100% - 40px);
      max-height: calc(100% - 40px);
      // background: #FFFFFF;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.11);
      opacity: 0;

      transform: scale(0.98);

      transition: all 100ms ease-in-out;

      background: #3a65a4;
      box-shadow: 0 0 10px rgba(25, 53, 94, 1);

      @include screen("medium") {
        top: 3%;
        position: absolute;
      }
      &.show {
        opacity: 1;
        transform: scale(1);
      }

      .topButtons {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;

        @include screen("medium") {
          flex-direction: column;
          margin-top: 0;
        }
        button {
          width: 160px;
          height: 40px;
          // background: white;
          // color: #707070;
          background: #264c81;
          color: #91a4bf;
          border-color: #264c81;
          box-shadow: none;
          border-radius: 10px 10px 0 0;
          font-weight: bold;
          font-size: 16px;
          margin-right: 2px;
          border-bottom: none;
          @include screen("medium") {
            width: 100%;
            height: auto;
            margin-right: 0;
            margin-bottom: 2px;
          }
          &.blue {
            // background: #0056A3;
            background: #0e3b7a;
            color: $primary-color-white;
          }
        }
      }

      .bbPackages {
        position: relative;
        max-height: 300px;
        overflow: hidden;
        box-sizing: border-box;
        // border: 1px solid #e0e9f2;
        border-radius: 0 10px 10px;
        padding: 5px;

        border: 1px solid #0e3b7a;
        background-color: #0e3b7a;
        @include screen("medium") {
          overflow: auto;
        }
        @include screen("small") {
          max-height: 700px;
        }
        &:hover {
          overflow: auto;
          overflow-y: overlay;
          @include scroll-bar-styles;
        }

        .package-item-wrapper {
          width: 25%;
          float: left;
          padding: 10px;
          box-sizing: border-box;

          @include screen("medium") {
            width: 100%;
          }
          .package-item {
            padding: 20px 12px;
            box-sizing: border-box;
            border-radius: 15px;
            // box-shadow: 0 0 10px #c4cae6;
            // border: 2px solid #ffffff;
            box-shadow: 0 0 10px #07234b;
            border: 2px solid #3b649f;
            transition: all 200ms ease-in-out;
            cursor: pointer;

            &::after {
              content: "";
              width: 36px;
              height: 36px;
              position: absolute;
              border-radius: 36px;
              background-color: #08ac31;
              background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAALCAYAAACgR9dcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAKlJREFUKJGV0DuKQkEQBVBdhDirUDGSQTA2kQlFZAIzd2FkaOAaBFfiAkRwC4P4CTQZv8fAFpsH8nyVNHTVuVCVy2UsNLDCNCus4+BRqyzwG/sA/9H8FFaxC/CI1rPRxwRfb2AF2wBP+Imbzx2WKCZgGZvQv6CdTB541QKF8F/COoKddzsNo4A5aviLYDftKKMo4BzeK34/uWge4yjghl4qTATMAhynzd8BBJsBlakQ16kAAAAASUVORK5CYII=");
              border: 3px solid #ffffff;
              background-repeat: no-repeat;
              background-position: center;
              right: 20px;
              bottom: 20px;
              opacity: 0;
              transform: scale(0.55) !important;
              transition: all 100ms ease-in-out;
            }

            &:hover {
              // border-color: #c98ee7; */
              // background-color: #f3faff;
              // border-color: #f3faff;

              background-color: #005ea8;
              border-color: #2984c5;
              transform: scale(1.02);
              transition: all 150ms ease-in-out;
            }

            &.selected {
              border: 2px solid #f6fbf7;
              // background-color: #f6fbf7;
              background-color: #005ea8;
              border-color: #2984c5;
              box-sizing: border-box;
              border-radius: 14px;
              transform: scale(1.05) !important;

              position: relative;

              &::after {
                opacity: 1;
                transform: scale(1) !important;
              }
            }

            .packageName {
              border-radius: 5px 5px 0 0;
              font-weight: bold;
              text-align: center;
              font-size: 19px;
              // color: #0a57a1;
              color: #02c5ff;
            }

            .priceDetails {
              font-weight: bold;
              font-size: 15px;
              color: #65809a;
              text-align: center;
              padding: 13px;
              margin-bottom: 10px;

              .price {
                font-size: 20px;
                // color: #355c82;
                color: #f3f3f3;
                font-weight: 800;
              }

              .per-month {
                font-size: 14px;
                font-weight: normal;
                color: #7797c3;
              }

              > div {
                > button {
                  background: #561d7e;
                  border-radius: 28px;
                  color: #e1f0fa;
                  z-index: 0;
                  padding: 3px 15px;
                }
              }
            }

            .amountDetails {
              font-weight: bold;
              font-size: 14px;
              color: #0056a3;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              justify-content: center;
              margin: 20px 0 4px 0;

              .package-box {
                background-color: #c9e2fa;
                padding: 8px 10px;
                margin: 5px;
                border-radius: 10px;

                &.any {
                  min-height: 52px;
                  display: flex;
                  align-items: center;

                  .package-type {
                    font-weight: 600;
                  }
                }

                .package-type {
                  font-size: 14px;
                  line-height: 12px;
                }

                .package-amount {
                  font-size: 13px;
                  font-weight: 600;
                }

                &.free {
                  background-color: #f2e1fa;

                  .package-type,
                  .package-amount {
                    color: #6c1d91;
                  }
                }
              }

              .plus {
                width: 30px;
                height: 30px;
                position: relative;
                margin: 5px;

                &::before {
                  content: "";
                  position: absolute;
                  width: 16px;
                  height: 3px;
                  top: 0;
                  right: 0;
                  left: 0;
                  bottom: 0;
                  margin: auto;
                  background-color: #5a6875;
                  // transform: rotate(45deg);
                  border-radius: 4px;
                }

                &::after {
                  content: "";
                  position: absolute;
                  height: 16px;
                  width: 3px;
                  top: 0;
                  right: 0;
                  left: 0;
                  bottom: 0;
                  margin: auto;
                  background-color: #5a6875;
                  // transform: rotate(45deg);
                  border-radius: 4px;
                }
              }
            }
          }
        }

        // > div {
        //   min-width: 150px;
        //   min-height: 250px;
        //   font-weight: bold;
        //   border: 3px solid #7cabd9;
        //   box-sizing: border-box;
        //   border-radius: 11px;
        //   margin-top: 0.6rem;

        // }
      }
    }

    .voicePack {
      width: calc(100% - 40px);
      max-height: calc(100% - 40px);
      // background: #FFFFFF;
      background: #3a65a4;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: 0 0 10px rgb(0 0 0 / 11%);
      opacity: 0;

      transform: scale(0.98);

      transition: all 100ms ease-in-out;

      @include screen("medium") {
        top: 3%;
        position: absolute;
        overflow: auto;
      }
      &.show {
        opacity: 1;
        transform: scale(1);
      }

      .package-item-wrapper {
        width: 20%;
        float: left;
        padding: 10px;
        box-sizing: border-box;

        @include screen("medium") {
          width: 100%;
        }
        .package-item {
          border-radius: 15px;
          min-height: 200px;
          padding: 20px;
          color: #ffffff;
          position: relative;
          cursor: pointer;
          background: linear-gradient(
            0deg,
            rgba(256, 256, 256, 0.24) 0%,
            rgba(34, 193, 195, 0) 100%
          );

          .count {
            display: flex;

            .number {
              font-size: 16px;
              font-weight: 600;
              width: 40px;
              height: 40px;
              background-color: rgba(255, 255, 255, 0.815);
              border-radius: 40px;
              text-align: center;
              line-height: 40px;
              margin-right: 6px;
            }

            .name {
              font-size: 16px;
              line-height: 40px;
            }
          }

          .package-name {
            font-size: 20px;
            font-weight: 600;
            margin-top: 10px;
          }

          .price {
            font-size: 18px;
            position: absolute;
            bottom: 20px;
          }

          transition: all 150ms ease-in-out;

          &:hover {
            transform: scale(1.02);
          }

          &::after {
            content: "";
            width: 36px;
            height: 36px;
            position: absolute;
            border-radius: 36px;
            background-color: #08ac31;
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAALCAYAAACgR9dcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAKlJREFUKJGV0DuKQkEQBVBdhDirUDGSQTA2kQlFZAIzd2FkaOAaBFfiAkRwC4P4CTQZv8fAFpsH8nyVNHTVuVCVy2UsNLDCNCus4+BRqyzwG/sA/9H8FFaxC/CI1rPRxwRfb2AF2wBP+Imbzx2WKCZgGZvQv6CdTB541QKF8F/COoKddzsNo4A5aviLYDftKKMo4BzeK34/uWge4yjghl4qTATMAhynzd8BBJsBlakQ16kAAAAASUVORK5CYII=");
            background-repeat: no-repeat;
            background-position: center;
            right: 20px;
            bottom: 20px;
            opacity: 0;
            transform: scale(0.55) !important;
            transition: all 100ms ease-in-out;
            border: 3px solid #ffffff;
          }

          &:hover {
            // border-color: #c98ee7; */
            background-color: #005ea8;
            border-color: #f3faff;
            transform: scale(1.02);
            transition: all 150ms ease-in-out;
          }

          &.selected {
            // border: 2px solid #07ac31;
            // background-color: #f6fbf7;
            background-color: #005ea8;
            border-color: #2984c5;
            box-sizing: border-box;
            border-radius: 14px;
            transform: scale(1.05) !important;

            position: relative;

            &::after {
              opacity: 1;
              transform: scale(1) !important;
            }
          }
        }
      }
    }
  }

  .bodyView {
    display: flex;
    min-height: 365px;

    .form-container {
      width: 100%;
      width: 100%;
      // max-width: 930px;
      margin: auto;
      // border: 1px solid #ececec;
      /* box-shadow: 0 0 1px #e2e2e2; */
      box-sizing: border-box;
      border-radius: 10px;
      padding: 2rem;

      @include screen("medium") {
        padding: 1rem;
      }
      .pkg-selection-container {
        margin-bottom: 30px;
        > label {
          color: #1057a6;
          font-size: 15px;
          font-weight: 600;
        }
        .package-set {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          margin-bottom: 10px;

          .package-item-contianer {
            width: 160px;
            height: 200px;
            position: relative;
            padding: 10px;
            box-sizing: border-box;
            @include screen("small") {
              width: 100%;
            }
            .package-item {
              position: relative;
              width: 100%;
              height: 100%;

              border-radius: 20px;
              text-align: center;
              cursor: pointer;

              transition: all 200ms ease-in-out;

              .item-container {
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 20px;
                border: 2px dashed #b1bfd6;

                &:hover {
                  // border-color: #23569c;
                  background-color: #cae8ff !important;
                  border-color: #93b6ce !important;
                }

                .plus {
                  width: 44px;
                  height: 44px;
                  border: 2px solid #c3dbfe;
                  border-radius: 44px;
                  margin: auto;
                  margin-top: 40px;
                  margin-bottom: 10px;
                  position: relative;

                  &::before {
                    content: "";
                    width: 14px;
                    height: 2px;
                    border-radius: 6px;
                    background-color: #c3dbfe;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                  }

                  &::after {
                    content: "";
                    width: 2px;
                    height: 14px;
                    border-radius: 6px;
                    background-color: #c3dbfe;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                  }
                }

                .name {
                  font-weight: 600;
                  font-size: 14px;
                  max-width: 120px;
                  margin: auto;
                  // color: #6a84a8;
                  color: #c3dbfe;
                }

                transition: all 200ms ease-in-out;

                &:hover {
                  // background-color: #f8fbff;
                  background-color: #005ea8;
                  border-color: #2984c5;

                  .plus {
                    border-color: #23569c;

                    &::before {
                      background-color: #23569c;
                    }

                    &::after {
                      background-color: #23569c;
                    }
                  }

                  .name {
                    color: #23569c;
                  }
                }

                &.voice {
                  background: #00ad3d;
                  background: linear-gradient(35deg, #00dcff 0%, #00ad3d 100%);
                  border: none;
                  margin: auto;

                  .name {
                    padding-top: 31px;
                    margin-bottom: 10px;
                    max-width: 90px;
                    color: #f6fbf7;
                    font-size: 14px;
                    position: relative;
                  }

                  .price {
                    font-weight: 600;
                    color: #f6fbf7;
                  }

                  .voice-icon {
                    width: 44px;
                    height: 44px;
                    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAABE5JREFUWIXFmd+LVVUUx7/7jE/DqKSi9dBDZiUKo0EJRdG8jE6BGVmGGv0HQfTQQ4Ez/oheUyJ7iKDALBEbtammggpKtBlFQ81Jo8mICnxw1NEym08Pex/u8s659559z7kzCy6HvfZe3/W9+6yz99prOxUQoEPSw5IekbRU0l2S5kvqCEOuSPpL0llJpyR9I+lb59yVIn5jSTrgUWAP8Dfxcg34AOgBXKz/KANgraRNkjqN+oak7yUNSxqRn9Gx0Ddb0gJJiyXdJ+l+STOM7XFJm51z/bHEGxFdBHxuZmkCGAQ2hLDIi9MBbAxYEwbvU2BhWWTXA5cM+F6gs7FlQ9xlwEcGdwxYVxR0qwEcBbqLEs3w0QP8avz0NQv0hgHZD9xSLtWbfM0BPjb+dsQCbDPGbwJJi7han23AzuiZBtYZo52tpZnpf7vxv6HR4EXmA9s/FTObwaENOBg4XATuqDc4Xbp+aWXMNpIQ0+mH+EmtQWvNq1g5xRyz+PQYPmuqOx1wInTunSaOk4TKOn0Mu43jcwPwu0/DTQF4ETgH/Bx+PwLzW0B4OZUdcaXt2BOUgzmBTjNZesomHHx9EfB3S1KCzwVWh/53c+JkrR4PlEEwQ94LzzVAuw2H6+RMZIChjBk+0gq2wGzg3zQsEvnkW5KGIxLr0QzdijzxHyvOuTFJR0OzK5E/KUjSUATODxm6fyTNLcCtngyH55JE0t2hMRIB8FVV+5SkFc65an1ZciY870kkzQuNPyIADkn63bQ3O+eyZr0sSbnNS3TzgTGXOOcmJNnEaAswo9b4EuRyeM60yxORIK+r8s8XS3qpKKs8kqgyszNjDJ1z45L6jGor8HhJvKol5XY5kXQhNG5rAuhtSemJN5G0K4s00AUsrdZHSMrtgqjkntubQcKfhE+YDeQ/4OU0poFOfC0C4DDQFzar3LkHleNav4DXQuNQM4QD4O1VpAHOAE8DJzN2RYDTEfiHg82rNu+8DkTFcRVoB7CvBrksOZcT127N3amj9JXVP0M1Bk+A5/FHm7IIPxfGjwPtqfLDoMyVXuZwMgfoBX6rQ/hsTqwvw/j3rTINiwlgWRmkA24CPAi8EiblCDCCT/hfyGF/LzUSeAccDx37yiJcVPAnd4CjVFc6gSfN61o1TRwtn8cMn9W1Bg2GAaNM7zF/LnA+cBmoN/BOfBUR4ADQNoU8Uw5twEDgUL+QEgxsqeqtKeJp/e8w/tfnNdpijN6htalj6rO6GLgpFsCWWw+0MqZDzDZfbjVAdqZHW7F64BOh88ZPb1HAZ8yHCD5fKLy54Ks6/Qb3IvBUUdwUfCHwmQFPL2U2EpEwAbOAZ5l8KTNAo9UgSOy11xOSeiUtN+ob8iWCIfmT95+SLoW+WZJuVe1rr2OS+pxzB2N4RAl+G18F7AauEi/jwC6gm1ZfLGaQb5f0kKQuSUvkaxwLNPnq9if52sXXkr5zzl1t1uf/Gi0Q31b/TZMAAAAASUVORK5CYII=");
                    background-repeat: no-repeat;
                    margin: auto;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 20px;
                  }
                }

                &.internet {
                  background: rgb(18, 235, 229);
                  background: linear-gradient(
                    35deg,
                    rgba(18, 235, 229, 1) 0%,
                    rgba(0, 120, 249, 1) 100%
                  );
                  border: none;
                  margin: auto;

                  .name {
                    color: #f6fbf7;
                    font-size: 14px;
                    font-weight: 600;
                    padding-top: 10px;
                    margin-bottom: 6px;
                  }

                  .pkg-size {
                    color: #f6fbf7;
                    background-color: rgba(51, 51, 51, 0.3);
                    font-weight: 600;
                    width: fit-content;
                    padding: 2px 6px;
                    border-radius: 6px;
                    margin: auto;
                    margin-bottom: 6px;
                  }

                  .pkg-price {
                    color: #f6fbf7;
                    font-weight: 600;
                  }

                  .pkg-month {
                    color: #f6fbf7;
                    font-size: 12px;
                    line-height: 10px;
                  }

                  .internet-icon {
                    width: 44px;
                    height: 44px;
                    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAABM9JREFUWIXFmUtsVkUUx//T1i6wpQGM6MqAKJUqxYhRE41KAhSjoGJqAB8bEdMY3RlXgIoE0A1EQV34SgwKyLsoGgVjUXxAIGljigsqC8WICc+KCPxc3HPTQ/v1++58DzjJTb+ZOed//nd67syZM0ElCFAn6S5Jd0tqknSdpCsl1ZnKSUl/SvpVUpekbyR1hBBOluI3lmQApgFrgNPEyz/Ax0ALEGL9RxkAMyXNlzTedZ+V9KOknyV1K5nRYzbWIGmkpEZJEyXdKqnG2e6T9FIIYWMs8UJExwBfuFk6D2wHZltYZMWpA+YY1nmH9xkwulxkZwHHHfg6YHxhy4K4zcAGh3sMaC0V9BUH2ANMLpVoDh8twG/Oz8Jigd5wIJuAYeWleoGv4cBW529FLMAiZ7wSqKoQV++zGlgVPdNAqzNaVVmaOf0vd/5nF1Ie4z6wTRdjZnNwqAa2GIejwKh8yunSdbCSMVtILKbTD3HbYEoz3b9iShFOGoHngA+BbfZ8YH1ji8BrcXxm9B8MwH4bXBcJfAewg8LyNXB7JHa6Tu/Fb+MkuQEku0+mTcFibRlwztl+Dyy1WX3efu+mb1c7Z32Zvg1ggrOd4gfWWOf2CLJr3extABrz6I8j+YhT+SSC9JdmszrtqCPJoKDQMtIH8rrp/wfMc/21wCSgzZ57gVo33gacNdtlGX09bvq9wBAfDmfIkMiQxGwaBk+7/nnAYQbKH8DcfqTT8Lgtg78Gm5gkLIAl1vgu4xvvMP311g7A247gMeBboIMLk6Y3HUYaHl9l9Lnb9BeLvkV6eca37ST5EG6wvmccqcVAvdOvdxMC8JT1NxlGJ9CQwW+a12wU0G2NtixvawCj7G8tfWGwJI/+a6bzOxbTROS/wLNm/4uAv63xUFaAfmBNwPx0pixEhtkTrK/BdJqK9JFuan8J+NcaZcl1gStcCIwoE+YUwzvt10LKAV5pqVJyFJek+nyKl1hSbidqJB2RNFzS1TEIJHnBO5Iu7zdU7X7vAc71Gz8laW4I4YcIdym3IzWSDki6XlJsRjVd0k0FdK7JYxtDON32u2skdUq6X0nNIEbS7GmnpJUZbdok3aPIeoiSmoYkddUoKR+9KGkiUB9COBEJdjCEsDaLIjBNCeHMYsvlLdbcWSWpQ9JpSZdJeiAG7CLJDCXVol5Ju6qsMLfZBp+8ZLQGlyfs76YQQm9a53pPUqukyUBzCGF/BOBYXNZWSDcCV8DNkiZZ830/EIB9tpuszwj2KsXLoow+0qxuT7rNBzf4sKRPrdkSQsh7+gBulLRUUm0+vRxyRtILIYSuAvj3SWq35vQQwpZcStvtjXq4tMf8EcAh49KeT/FakgQcYDNQPahyhYTkvNhuHPIXUszAl6reukg8vf8Vzv+srEYvO6N3gZrCVqUJA4uB82MBfLl1cyVj2mK2+HKrA/Iz3QNMLTPX9NR+yPlZUCrgo+5DBFgPNJeB6ARgo8M9CjxSKm4KPhr43IGnlzJzcKfkDDhDgccYeCnTTqHVwCT22utBSQskTXDdZyX9ZE+3pMOSjtvYUElXafBrr72SFubcFMolJNv4VGA1SfkoVk4BHwGTqfTFYg7yQyTdqSTHHafk5DJSA69uDyi5ut0paVcIobdYn/8DWhzSJtyYfh0AAAAASUVORK5CYII=");
                    background-repeat: no-repeat;
                    margin: auto;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 20px;
                  }
                }

                &.peo {
                  background: rgb(162, 39, 237);
                  background: linear-gradient(
                    35deg,
                    rgba(162, 39, 237, 1) 0%,
                    rgba(95, 39, 193, 1) 100%
                  );
                  border: none;
                  margin: auto;

                  .channels {
                    display: flex;
                    justify-content: center;
                    padding-top: 10px;
                    margin-bottom: 6px;

                    .number {
                      background-color: #f6fbf7;
                      width: 36px;
                      height: 36px;
                      border-radius: 36px;
                      text-align: center;
                      line-height: 36px;
                      font-size: 15px;
                      font-weight: bold;
                      color: #1f1f1f;
                      font-size: 15px;
                      margin-right: 6px;
                    }

                    span {
                      display: block;
                      color: #f6fbf7;
                      font-size: 14px;
                      line-height: 36px;
                    }
                  }

                  .name {
                    color: #f6fbf7;
                    font-size: 14px;
                    margin-top: 10px;
                  }

                  .peo-icon {
                    width: 44px;
                    height: 44px;
                    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAABCJJREFUWIXNmc+LlVUYxz/nzgRRo1INlVGLzFRG0JGUNo6JpE6BGCmGWssoiP6BFmo/aF0SapsWQVoi5o/MtBYGuklHFFLUFpnQL3LhOOMPavDT4j0Xz4x37n3f+2Ou383Le87zfJ/vPfe85zznOYEGoHYBfcBzwGzgaeBhoCuaDAN/A78AZ4AfgaMhhOFG4hYVGdQX1J3qTYvjhvql2q+GovELOairgA3AnKR5BPgJOAGcJxvRwdg3BXgEmAXMBxYAnYnvKeDdEMKeosJrCZ2uHk5G6ZZ6SF0Xp0Veni51feS6lfAdVKc1S+xa9WpCvkudU9uzJu9c9euEd1Bd0yjp+wnhRXVpo0IrxOhXf0vibKqX6JOEZK/6QHOljor1oPpNEm9zUYIPEuctaqlFWtOYHerWwiOtrkmctrZWZsX4Hyfx19Uynp58YHsnYmQraOhQ90cNV9QnqxmXl65fWzlnayHO6fKH+O14RquSv2LZBGuspKc/0bNybGdQT8fOXQWJg/qRuqiKzaJoU3RnLa/TJ0f5muUGmu0+hTYFdUn0G64kOoodjjZLCnL3entHXJZ27IyNh4oQJv6vR+JrqSh1oToU+96uk/v7qG0HQDDLBf4B7gXWhxC25yDpAR4b0/wGsBq4BrwZ27YB9wO7gE/H2P8RQjibI9ZrwOfADaA7nQ7/miORUWfbPMzOEW+K+l95WnSSJd8AJ3Im1lPj8yxwNId9JSwEeiLXmWqGIYRBdQB4FljcSXZSADheMOiREMJbRZUCqFvIBOfFCTLBPSVgRmw8X0/wCcK5+JxZArrjy59tEpMHZW3dJUYfGO9WDMXnpDS5sR1KiqLE7ZGd1E4hNVDWNlQCLseXqeMY3w0oa7tcAi7El5ltEpMHs+LzfAn4Ob4saJOYPJgfn2dKZOUjgPnqXTeP1SnAM/H1SCfZ9nqTLPlZAdRMfiKeUJ+vU8fjBWxXklWLrgPHAFC/ypteqn1NTH76csT7Idpuh1hbU/uBg2Rr8bwQwukqBPcA73BnelkUvwMfhhBGqsSaBwxEnctDCIfLHUE9FX/J7gaFNA1mJ3fVAccer9SXk79qeZs0pnpeTPSsGM/oUDS4aHuP+Q+pl6KWA9UMnzKrIqruUzsmUGdZQ4d6IGqoXkiJDmmpatsE6Uzjb07ir83r9F7i9JnaWdurMXhnMXBDUYK03LqvlXM6ztn6y60JUTrSF1uxepid2i8lcTY2SvhK8iGq7lbnNkFor7on4b2irm6Ut0w+Tf0uIS9fyqy3QMKkTlZf9c5LmQPWWg0iihbnXgI2Ar1J8whZieA42cn7L+Bq7JsMPMr4114ngU0hhP1FdBSC2Ta+XN2hXrc4rqlfqEtt9cViBfH3kVVxFpMVRmaQXSSOvbq9QFbhOQIcCyFcrzfm/5v+QJuK+ZhyAAAAAElFTkSuQmCC");
                    background-repeat: no-repeat;
                    margin: auto;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 20px;
                  }
                }
              }

              .change {
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 20px;
                border: 2px dashed #b1bfd6;
                transition: all 200ms ease-in-out;
                z-index: 2;
                opacity: 0;

                &:hover {
                  opacity: 1;
                  border-color: #23569c;
                }

                .plus {
                  width: 44px;
                  height: 44px;
                  border: 2px solid #8aa3c5;
                  border-radius: 44px;
                  margin: auto;
                  margin-top: 40px;
                  margin-bottom: 10px;
                  position: relative;

                  &::before {
                    content: "";
                    width: 14px;
                    height: 2px;
                    border-radius: 6px;
                    background-color: #8aa3c5;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                  }

                  &::after {
                    content: "";
                    width: 2px;
                    height: 14px;
                    border-radius: 6px;
                    background-color: #8aa3c5;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                  }
                }

                .edit {
                  width: 84px;
                  font-size: 13px;
                  color: #23569c;
                  border-radius: 20px;
                  padding: 6px 14px 6px 28px;
                  height: 34px;
                  line-height: 21px;
                  font-weight: 600;
                  position: relative;
                  margin: auto;
                  margin-bottom: 14px;
                  margin-top: 14px;
                  border: 1px solid #23569c;
                  background-color: #edf5fe;
                  cursor: pointer;

                  &::before {
                    content: " ";
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAVJJREFUOI3dkr8vQwEUhb/znslm0qQYXl83icFq4G9o34tfCfIw8JdI7CKtdGrEs9o1wchqKQuCySA2eg08aV+1lImz3psv55x74V/KC7ZHvLA0A6b0zO0ZVqjk5diR0NLA6NnQQzB+QK1mydzpBZYLyr7c50PQIHAqU5Q7H95qdvptYC4o+8hqoCxm6y/97gRwitmqF5ank72+HmEZAElr7tMzSGNgN1jjJNltKzUtr1DJv8fMmLQga2RAm+/ja4ypi/3lerLfNXJzZ0Kzl3tRVThXwMubs1ZYV6BXqOSTmCYt1uMo9oNS0bAqcItpMg3rGDkd83IvqvpBqWjSLnD3mbOOwN/A2oC/hbUAm15jUGiuHkexH+6Eb53ZfafO0vr4Q8nmDWUNoosfwuCTKzviMRfurPwE1uIwkRkxGMA1pi876whs4B47NDaawOVeYX9Dr0mwvgW2ckntAAAAAElFTkSuQmCC");
                    left: 14px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    border-radius: 20px;
                  }
                }

                .remove {
                  width: 84px;
                  font-size: 13px;
                  color: #df4747;
                  border-radius: 20px;
                  padding: 6px 14px 6px 28px;
                  height: 34px;
                  line-height: 21px;
                  font-weight: 600;
                  position: relative;
                  margin: auto;
                  border: 1px solid #df4747;
                  background-color: #fff3f3;
                  cursor: pointer;

                  &::before {
                    content: " ";
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAASpJREFUOI3tlDFOAkEUhr83rBsqKKkwMcbO0sJES5PZPYAn8AYWnsHKA5h4ASxNXAiNhSY0llZGoMIa7SbLPBsxLK4DIqVfN3/e++fPvJmBNSNl4nOa1iqqNtQ4EensZNnbvB6VFcfeNz20Qoax6i7wVJpwkKb74v3pVFSoAcGEQEfgK6Eac7GVZb0IQFWbwPECg3mszixU9RroFSqG1rYGtlAXZGCtDq0tHI35ZaqFlA5lykuSHBrVAxfHVwCxcyde5GG73b7/qSeY0MARcF51rlF1rgGcf2qhnvXyb/h3gtemMplcqsiNqdf7AIzHe+L968qGm93uCBjNSI8rJewnyXLvWr+/0oKhwjuAqAa/rrKeUsONPD/Lo+jWiyw1LKPqozy/W3bzlfgAGnVhkxj4zgkAAAAASUVORK5CYII=");
                    left: 5px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    // background-color: #0056A3;
                    border-radius: 20px;
                  }
                }

                .name {
                  font-weight: 600;
                  font-size: 14px;
                  max-width: 120px;
                  margin: auto;
                  margin-top: 26px;
                  color: #23569c;
                }

                transition: all 200ms ease-in-out;

                background-color: rgba(248, 251, 255, 0.918);

                .plus {
                  border-color: #23569c;

                  &::before {
                    background-color: #23569c;
                  }

                  &::after {
                    background-color: #23569c;
                  }
                }
              }
            }
          }
        }
        .package-info {
          width: fit-content;
          text-align: center;
          margin-top: 16px;
          color: #d76200;
          font-size: 13px;
          font-weight: 600;
          margin: auto;
          padding: 4px 8px;
          background-color: #fef5cf;
          border-radius: 20px;
          border: 1px solid #f4e8b4;
          p {
            margin-right: 2px !important;
            color: #d76200;
          }
        }
      }

      .input-columns {
        display: flex;

        > div {
          flex: 1;
        }

        .form-address-container {
          padding: 0px;
          box-sizing: border-box;
          //   >div{    width: 100%;
          //     height: 100%;
          //     margin: 0px;
          //     padding: 0px;
          //     position: relative;
          //     border: 1px solid #e4e4e4;
          //     border-radius: 10px;
          //   >div{
          //     border-radius: 4px;
          //   }}
        }
      }
    }
  }
  .container-footer {
    border-top: 0px;
  }

  @media screen and (max-width: 767px) {
    position: relative;
  }
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
