.customerDetails {
    width: 100%;
    height: 100%;
    //min-height: 540px;
    margin: auto;
    position: relative;
  
  
    .form-container {
      .form-inputs-container {
        width: 50%;
        float: left;
        @include screen("medium") {
          width:100%;
          padding-right: 0px;
        }
      }
  
      .autoCom {
        width: 100%;
  
        .MuiFormControl-root {
          .MuiInputBase-root {
            padding-right: 39px;
            height: 40px;
            font-size: 14px;
  color: $input-color;
            input {
              padding: 0px;
            }
  
            fieldset {
              border-color: $input-border;
              border-radius: 7px;
            }
  
            button {
              position: absolute;
              right: -3px;
              top: -2px;
              height: 32px;
              padding: 0 10px;
              background: #8a99a6;
              box-shadow: none;
              border-radius: 6px;
              border: none;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              text-align: center;
              color: #FFFFFF;
              width: 30px;
            }
          }
        }
      }
    }
  
    .customerDetailForm {
      width: 100%;
      margin: auto;
      // border: 1px solid #ececec;
      /* box-shadow: 0 0 1px #e2e2e2; */
      box-sizing: border-box;
      border-radius: 10px;
      padding: 2rem;
      @include screen("medium") {
        padding: 1rem;
      }
    }
  
    .otpInput {
      position: absolute;
      width: 100%;
      height: 100%;
      //min-height: 550px;
      background: rgba(102, 107, 134, 0.10);
      mix-blend-mode: normal;
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 5;
  
      .otpInputBody {
        max-width: 600px;
        max-height: 600px;
        min-width: 500px;
        background: $panel-blue-bg;
        border-radius: 8px;
        //padding: 2rem;
  
        .title {
          @include subBodyBackgroundTitle();
          > span{
            float: right;
            margin: 10px;
            &:hover {
              cursor: pointer;
            }
          }
        }
  
        .inputArea {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 150px;
          padding: 0;
  
          input {
            padding-left: 4px;
          }
        }
  
        .resend {
          color: $dark-title-white;
          cursor: pointer;
  
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  
  
} 

.fileUploadLTE {
  border: 1px solid #5675A2;
  border-radius: 5px;
  padding: 10px;

  .header {
    display: flex;
    width: 100%;

    .titles {
      // font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;

      color: #95C3E8;

      & p {
        margin: 0.6rem 0 0 0;
      }
    }
  }

  .formUpload {
    margin: 1rem 0 0;
    box-sizing: border-box;
    padding: 1.2rem;
    background: #0B3065;
    border: 1px solid #0B3065;
    border-radius: 5px;

    .nicUpload {
      .nicUploadSelect {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        // margin: 1rem 0 0;
        // padding: 1.2rem;
  
        >div {
        
          // font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 26px;
          line-height: 39px;
          /* identical to box height */
          cursor: pointer;
          width: 95%;
  
        }
        .active {
          color: #FFFFFF;
          border-bottom: 3px solid #FFFFFF;
        }
        .deactive {
          color: rgba(255, 255, 255, 0.3);
          border-bottom: 1px solid #C4C4C4;
        }
      }
      .nicUploadimage {
        margin: 1rem 0 0;
        border: 1px dashed #C4C4C4;
        border-radius: 10px;
        padding: 2.5rem;

        > div {
          align-items: center;
          margin-right: 30px;
          text-align:center;
          // cursor: pointer;
  
          > label {
            width: 100%;
            cursor: pointer;
            height: 200px;
            > input {
              // display: none;
              cursor: pointer;
              height: 200px;
              background: #0B3065;
            }
            > span {
              margin: 1rem 0 0;
              padding: 1.2rem;
            }
          }
  
          
  
        }
        > span {
          font-weight: 600;
          font-size: 19px;
          line-height: 28px;
          /* identical to box height */


          color: #E12A2A;
        }
      }
    }

    .uploadField {
      display: flex;
      justify-content: space-around;
      

      @include screen("medium") {
        flex-direction: column;
        align-items: center;
      }
      
      > div {
        align-items: center;
        // margin-right: 30px;
        height: 150px;
        padding: 1.2rem;
        max-width: 500px;

        > label {
          width: 40%;
        }

        > input {

        }

      }

      
    }

    .error-msg {
      // position: absolute;
      // margin-left: 200px;
      // margin-right: 0;
      // margin-top: -20px;
      // float: right;
      color: red;
      font-size: 14px;
    }
    
  }
}