.noBroadbandAccount {
  @include subBodyBroadband();
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    max-width: 370px;
    max-height: 300px;
    background: #FFFFFF;
    border: 1px solid #C8DBE8;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 15px;
    color: #707070;

    > img {
      width: 221px;
      height: 166px;
      margin: auto;

    }

    .lastLine{
      display: flex;
      justify-content: space-evenly;
    }
  }

}