body {
  background: #E5E5E5;
  padding: 0;
  margin: 0;
  font-family: $fontFamily;

}

.containerLogin {
  display: grid;
  //height: 700px;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  grid-template-areas: "bannerView publicViews";

  //@media only screen and (max-width: 600px) {
  //  grid-template-areas: "loginView";
  //}


  @include customAlertViewSign('O');

  .title {
    @include subBodyBackgroundTitle();
  }

  @include TermCondistion(-138px, -30px);


}


.banner {
  grid-area: bannerView;

  & img.banner {
    max-width: 400px;
    min-width: 400px;
    width: auto;
    height: 610px;
    border-radius: 12px 0 0 12px;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
}


.formView {
  grid-area: publicViews;
  position: relative;
  /* max-height: 686px; */
  height: 610px;
  min-width: 388px;
  //max-width: 450px;
  max-width: 388px;
  background: $panel-bg;
  border-radius: 0 12px 12px 0;
  padding: 40px;

  &.alertBackground {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
  }


  & div {
    &.form {

      // width: 355px;
      @include screen("small") {
        width: 100%;

      }


    }


  }


  .logo-container {
    margin-bottom: 40px;

    .logo {
      width: 184px;
    }
  }

  & img {
    &.icons {
      width: 25px;
      height: 25px;
      position: absolute;
      margin: 8px;
    }

    &.eye {
      width: 18px;
      position: absolute;
      float: right;
      cursor: pointer;
      right: 14px;
      bottom: 12px;

      @media only screen and (max-width: 600px) {
        margin-left: 250px;
      }

      @media only screen and (max-width: 300px) {
        margin-left: 230px;
      }

    }


  }

  .btn-back {
    text-decoration: none;
    color: #8f8f8f;
    font-size: 13px;
    position: absolute;
    bottom: 40px;

    .back {
      display: flex;
      align-items: center;
      color: #8f8f8f;

      img {
        width: 22px;
        height: 22px;
        margin-right: 6px;
      }

      p {
        margin: 0px;
        color: $link-btn-color;

        transition: all 200ms ease-in-out;

        &:hover {

          color: $link-btn-color-hover;
        }
      }
    }

  }

  @media only screen and (max-width: 768px) {
    border-radius: 20px;
    padding: 20px;
    min-width: 388px;
  }
  @media only screen and (max-width: 390px) {
    margin: 2px;
    min-width: 280px;
  }
}

.social-icons {
  margin-top: 30px;

  .social-icon {
    margin-right: 8px;
    width: 48px;
    height: 48px;
    box-shadow: none;
  }

  .google-btn-sign {
    background-color: #ffffff;

  }

  .facebook-btn-sign {
    background-color: #ffffff;
  }

  .apple-btn-sign {
    background-color: #ffffff;
  }
}


.register-container {
  display: flex;
  font-size: 14px;
  margin-top: 24px;

  .register-label {
    margin-right: 6px;
    color: #777;
  }

  .btn-register {
    display: block;
    text-decoration: none;
    font-weight: 600;
    color: #1168b8;
    cursor: pointer;
  }

}


.login-form {
  .titles {
    font-size: 16px;
    @include subBodyBackgroundTitle();
    font-weight: 600;
  }

  .input-group {
    .input-container {


      border: 0.5px solid $form-border-highlight;
      border-bottom: none;
      padding: 10px;
      position: relative;
      border-radius: 0px;
      padding-bottom: 4px;
      padding: 10px 10px 5px 10px;

      label {
        margin-bottom: 0px;
        color: $dark-text-white;
        font-weight: normal;
        margin-top: 3px;
        line-height: 14px;
        height: 16px;
      }

      input {
        border: none;
        padding-left: 0px;
        height: 28px;
        color: $dark-text-white;
        background-color: $panel-bg;
        border-radius: 0px;
        /* Change the white to any color */

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #a8cbff;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #a8cbff;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: #a8cbff;
        }
      }

      /*Change text in autofill textbox*/
      input:-webkit-autofill {
        -webkit-text-fill-color: $dark-text-white !important;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $panel-bg inset !important;

      }

      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        &.error {
          border-color: #ffbdbd;
          border-bottom: none;
        }
      }

      &:only-child {
        &.error {
          border-bottom: 0.5px solid #ffbdbd;
        }
      }

      &:nth-child(2) {
        &.error {
          border-bottom: none;
        }
      }

      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom: 0.5px solid $form-border-highlight;

        &.error {
          border-bottom: 0.5px solid #ffbdbd;
        }
      }

      &.error {
        border: 0.5px solid #ffbdbd;
        background-color: #fff5f5;

        input {
          background-color: #fff5f5;
        }
      }
    }
  }

  .input-container-footer {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 23px;

    a {
      text-decoration: none;
      font-size: 14px;
      display: block;
      color: #8b8b8b;

      transition: all 200ms ease-in-out;

      p {
        margin: 0;
      }

      &:hover {

        color: #444444;
      }
    }

    button {

      margin-left: auto;
    }
  }

  .or {
    font-size: 14px;
    color: #777;
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    width: fit-content;
    margin: auto;
    padding: 0 10px;
    font-size: 12px;

  }

  .agree {
    margin-top: 24px;

    input {
      min-width: 0px;
      margin-right: 8px;
      cursor: pointer;
    }

    label {
      line-height: 17px;
      cursor: pointer;
      font-weight: normal;
    }

    strong {
      color: $link-color;
      font-weight: normal;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .message {
    font-size: 14px;
    color: #c3d3eb;
  }

  .btn-back {
    display: flex;
    align-items: center;

    img {

      height: 22px;
      margin-right: 6px;
    }

    &.back {
      p {
        color: #e4e4e4;
        margin: 0;
        font-size: 14px;
      }
    }
  }
}

.otpContainer {
  input {
    padding: 0;
  }
}

.fg {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
