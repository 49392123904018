
p {
  &.medium {
    font-size: 20px;
    overflow: hidden;
  }

  &.small {
    font-size: 14px;
    font-weight: 600;
  }

  &.extraSmall {
    font-size: 9px;
    font-weight: 600;
  }

  &.red {
    color: red;
  }

  &.black {
    color: #434343;
  }

  &.grey {
    color: #656565;
  }

  &.blue {
    color: #175AAF;
  }

  //used to create a line with text
  &.line {
    width: 100%;
    border-bottom: 0.5px solid $thin-line-bg;
    line-height: 0.1em;
    margin: 10px 0 20px;

    & span {
      background: $panel-bg;
      color: $dark-text-blue !important;
    }
  }


}



