@mixin transition($property, $speed, $effect) {
  -webkit-transition: $property $speed $effect;
  transition: $property $speed $effect;
}

@mixin hover-background($color) {
  background-color: $color;
  @include transition(background-color, 100ms, ease-in);

  &:hover {
    background-color: ($color);
  }
}

@mixin box-border($borderRadiusValue) {
  border: 1px solid $alert-box-shadow-color;
  box-sizing: border-box;
  box-shadow: 0 3px 4px rgb(0 0 0 / 15%);
  border-radius: $borderRadiusValue;
}

@mixin screen($breakpoint) {
  @if ($breakpoint == large) {
    @media (max-width: 992px) {
      @content
    }
  }
  @if ($breakpoint == medium) {
    @media (max-width: 768px) {
      @content
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 576px) {
      @content
    }
  }
}

@mixin subBodyBroadband() {
  // margin-top: 0.6rem;
  //box-shadow: 0 0 6px #cee1ed;
  //border: 1px solid #C8DBE8;
  box-sizing: border-box;
  border-radius: 8px;
  // min-height: 100%;
  background: #0F3B7A;
  position: relative;
}

@mixin subBodyBackgroundTitle() {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: $dark-title-higlight;
  padding: 2px 0 10px 2px;
}

@mixin customAlertView() {
  .alertView {
    position: absolute;
    //background: #2FD664;
    //width: 100%;
    //height: 100%;
    width: calc(100% - 20px);
    border-radius: 10px;
    height: calc(100% - 20px);
    background: rgb(163 199 234 / 24%);
    mix-blend-mode: normal;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    box-sizing: border-box;

    > div {

      padding: 10px;
      background: $alert-bg;
      /* border: 0.5px solid #E6E6E6; */
      box-sizing: border-box;
      box-shadow: 0 2px 10px $alert-box-shadow-color;
      border-radius: 10px;
      min-width: 300px;

      .title {
        font-size: 18px;
      }

      .mainText {
        font-size: 16px;
        text-align: center;
        color: #4d4d4d;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        .img-container {
          > img {
            width: 35px;
            margin-right: 10px;

          }
        }
      }

      .message {
        max-width: 250px;
        color: $dark-text-white;
      }

      .actionButton {
        display: flex;
        width: fit-content;
        /* margin-right: auto; */
        /* justify-content: space-evenly; */
        margin-left: auto;

        > button {
          margin-left: 10px;
        }

        .btn-ok {
          padding: 6px 26px;
        }
      }
    }

  }
}

@mixin customAlertViewTemp() {
  .alertView {
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: 10px;
    background: rgb(163 199 234 / 24%);
    mix-blend-mode: normal;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    box-sizing: border-box;
    margin: 10px 0 0 10px;

    .cusstateAlert {
      padding: 10px;
      background: $alert-cust-bg;
      /* border: 0.5px solid #E6E6E6; */
      box-sizing: border-box;
      box-shadow: 0 2px 10px $alert-box-shadow-color;
      border-radius: 10px;
      min-width: 300px;

      .title {
        font-size: 21px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        text-align: center;

        color: #707070;
      }

      .mainText {
        font-size: 16px;
        // text-align: center;
        color: #4d4d4d;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        .message {
          align-items: center;
          justify-content: center;
          max-width: 350px;
          color: #707070;
        }

        .link {
          font-size: 14px;
          justify-content: left;
          color: #0056A3;
          a {
            text-decoration: none;
          }
        }

      }

      .cus-image {
        padding: 1em;
      }

      .actionButtonCus {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }

    > div {


      padding: 10px;
      background: $alert-bg;
      /* border: 0.5px solid #E6E6E6; */
      box-sizing: border-box;
      box-shadow: 0 2px 10px $alert-box-shadow-color;
      border-radius: 10px;
      min-width: 300px;

      .title {
        font-size: 18px;
      }

      .mainText {
        font-size: 16px;
        text-align: center;
        color: #4d4d4d;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        .img-container {
          > img {
            width: 35px;
            margin-right: 10px;

          }
        }
      }
      .message {
        max-width: 350px;
        color: $dark-text-white;
      }
      .actionButton {
        display: flex;
        width: fit-content;
        /* margin-right: auto; */
        /* justify-content: space-evenly; */
        margin-left: auto;
      }

      .inputField {
        width: 60%;
        margin: 2rem auto;

        > input {
          padding: 0 1rem;
        }
      }

    }


  }
}

@mixin customAlertViewSign($type: 'F') {
  .alertView {
    position: absolute;
    //background: #2FD664;
    //width: 100%;
    //height: 100%;
    width: calc(100% - 20px);
    border-radius: 10px;
    height: calc(100% - 20px);
    background: rgb(163 199 234 / 24%);
    mix-blend-mode: normal;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    box-sizing: border-box;
    @if $type != 'F' {
      margin: -138px 0 0 -30px;
      @include screen('medium') {
        margin: -120px 0 0 -10px;
      }
    }
    @if $type != 'O' {
      margin: -5px 0 0 -5px;
    }
    //margin: -5px 0 0 -5px;
    //margin: -138px 0 0 -30px;


    > div {

      padding: 10px;
      background: $alert-bg;
      /* border: 0.5px solid #E6E6E6; */
      box-sizing: border-box;
      box-shadow: 0 2px 10px $alert-box-shadow-color;
      border-radius: 10px;
      min-width: 300px;

      .title {
        font-size: 18px;
      }

      .mainText {
        font-size: 16px;
        text-align: center;
        color: #4d4d4d;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        .img-container {
          > img {
            width: 35px;
            margin-right: 10px;

          }
        }
      }

      .message {
        max-width: 250px;
        color: $dark-text-white;
      }

      .actionButton {
        display: flex;
        width: fit-content;
        /* margin-right: auto; */
        /* justify-content: space-evenly; */
        margin-left: auto;

        > button {
          margin-left: 10px;
        }

        .btn-ok {
          padding: 6px 26px;
        }
      }
    }

  }
}

@mixin TermCondistion($top , $left) {
  .termConditon {
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: 10px;
    background: rgb(163 199 234 / 24%);
    mix-blend-mode: normal;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    //display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    box-sizing: border-box;
    margin: $top 0 0 $left;
    overflow-y: scroll;

    @include scroll-bar-styles();

    .headerTerm {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: white;
      margin: 0.6rem;
    }

    .bodyList {
      padding: 0.6rem;

      .title {
        @include subBodyBackgroundTitle()
      }

      .discription {
        margin: 0 0 15px 0;
        text-align: justify;
        color: white;

      }

    }

    @media only screen and (max-width: 768px) {
      margin: -117px 0 0 -11px;
    }

  }


}

@mixin scroll-bar-styles {

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    cursor: pointer;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: #ececec;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #919191;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #777777;
    cursor: pointer;
  }
}

@mixin set-btn-click-animation($scaleSize:0.98) {
  &:active {
    transform: scale($scaleSize, $scaleSize);
    transition: all .3 ease;

  }
}
