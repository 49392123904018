.packageUpgrade {
  width: 100%;
  //min-height: 530px;
  border-radius: 10px;
  background: $primary-color-white;
  padding: 10px;
  margin-top: 10px;
  box-shadow: 0 0 6px #cee1ed;
  // display: grid;
  // grid-template-columns: 30% 68%;
  // grid-template-areas: 'leftSide rightSide';
  // grid-column-gap: 5px;
  position: relative;

  .title {
    @include subBodyBackgroundTitle();
  }
  @include customAlertView();

  .current-package-container {



    >div {
      width: 100%;
      margin-bottom: 20px;
      border-radius: 5px;
      // margin: 3rem 0 0 1rem;



      .bodyView {
        width: 100%;
        background: $panel-helight-bg;
        border: 1px solid $panel-helight-border;
        border-radius: 12px;
        padding: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .current-package {
          display: flex;
          align-items: center;
          justify-content: center;

          .icon-correct {
            width: 30px;
            height: 30px;
            background-color: #1d72e8;
            border-radius: 30px;
            position: relative;
            margin-right: 6px;

            &::before {
              content: "";
              position: absolute;
              width: 6px;
              height: 3px;
              top: 15px;
              left: 7px;
              background-color: #ffffff;
              transform: rotate(45deg);
              border-radius: 4px;
            }

            &::after {
              content: "";
              position: absolute;
              width: 13px;
              height: 3px;
              top: 14px;
              left: 10px;
              background-color: #ffffff;
              transform: rotate(-45deg);
              border-radius: 4px;
            }
          }

          .titleCurrent {
            font-size: 14px;
            font-weight: 600;
            line-height: 30px;
            color: $panel-heiglight-text;
          }
        }

        .package-info {
          display: flex;
          align-items: center;

          .packageName {
            font-weight: bold;
            font-size: 17px;
            color: #ffffff;
            margin-right: 10px;
            background-color: #1d72e8;
            border-radius: 20px;
            padding: 4px 14px;
          }

          .priceDetails {
            font-size: 16px;
            font-weight: 600;
            color: $panel-heiglight-text;
          }
        }

        .amountDetails {
          font-weight: bold;
          font-size: 14px;
          color: #0056A3;
          display: flex;
          align-items: center;
          justify-content: center;

          .package-box {
            background-color: #c9e2fa;
            padding: 8px 10px;
            border-radius: 10px;

            .package-type {
              font-size: 14px;
              line-height: 12px;
            }

            .package-amount {
              font-size: 16px;
              font-weight: 600;
            }

            &.free {
              background-color: #f2e1fa;

              .package-type,
              .package-amount {
                color: #6c1d91;
              }
            }

          }

          .plus {
            width: 30px;
            height: 30px;
            position: relative;

            &::before {
              content: "";
              position: absolute;
              width: 16px;
              height: 3px;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              margin: auto;
              background-color: $panel-heiglight-text;
              // transform: rotate(45deg);
              border-radius: 4px;
            }

            &::after {
              content: "";
              position: absolute;
              height: 16px;
              width: 3px;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              margin: auto;
              background-color: $panel-heiglight-text;
              // transform: rotate(45deg);
              border-radius: 4px;
            }
          }
        }

      }
    }
  }

  .new-package-container {



    .topButtons {
      display: flex;
      // justify-content: space-between;

      button {
        height: 40px;
        background: $tab-button-normal;
        color: $tab-button-normal-text-color;
        box-shadow: none;
        border-radius: 10px 10px 0 0;
        font-size: 16px;
        margin-right: 2px;
        padding: 4px 20px;
        border-bottom: none;

        &.blue {
          // background: #0056A3;
          background: $tab-button-heighlight;
          color: $primary-color-white;   
          // border-bottom-color: #0056A3;
          border-bottom-color: $tab-button-heighlight;
        }
      }
    }

    .bodyView {
      padding: 40px 14px;
      box-sizing: border-box;
      border: 1px solid $panel-blue-border-color;
      border-radius: 0 10px 10px;
      max-width: 870px;

    .package-item{
   
      margin: 10px 0;
      padding: 20px 12px;
      box-sizing: border-box;
      border-radius: 15px;
      // box-shadow: 0 0 10px #c4cae6;
      // border: 1px solid #ffffff;
      box-shadow: $pkg-item-box-shadow;
      border: 1px solid $pkg-item-border;
      background:$pkg-item-bg;


      transition: all 200ms ease-in-out;

      &:hover{
        // border-color: #c98ee7;
        box-shadow: 0 0 10px $pkg-item-hover-bg;
        border: 1px solid $pkg-item-hover-border;
        transform: scale(1.02);
        transition: all 150ms ease-in-out;
      }
        .packageName {
          border-radius: 5px 5px 0 0;
          font-weight: bold;
          text-align: center;
          font-size: 19px;
          // color: #0a57a1;
          color: $pkg-item-name-color;
        }

        .priceDetails {
          font-weight: bold;
          font-size: 15px;
          // color: #65809a;
          color: $pkg-item-price-color;
          text-align: center;
          padding: 13px;
          margin-bottom: 10px;

          .price{
            font-size: 20px;
            // color: #355c82;
          color: $pkg-item-price-color;
            font-weight: 800;
          }
          .per-month{
            font-size: 14px;
            font-weight: normal;
            > span {
              font-weight: bold;
              font-size: large;
            }
          }
          >div {
            >button {
              // background: #561D7E;
              background: $upgrade-btn-bg;
              border-radius: 28px;
              color: #E1F0FA;
              z-index: 0;
              padding: 3px 15px;

            }
          }


        }

      .amountDetails {
          font-weight: bold;
          font-size: 14px;
          color: #0056A3;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 20px 0 4px 0;

          .package-box {
            background-color: #c9e2fa;
            padding: 8px 10px;
            border-radius: 10px;

            &.any{
              min-height: 52px;
              display: flex;
              align-items: center;

              .package-type{
                font-weight: 600;
                text-align: center;

                > span {
                  font-weight: bold;
                  font-size: large;
                  margin-left: 5px;
                }
              }
            }

            .package-type {
              font-size: 14px;
              line-height: 12px;
            }

            .package-amount {
              font-size: 16px;
              font-weight: 600;
            }

            &.free {
              background-color: #f2e1fa;

              .package-type,
              .package-amount {
                color: #6c1d91;
              }
            }

          }

          .plus {
            width: 30px;
            height: 30px;
            position: relative;

            &::before {
              content: "";
              position: absolute;
              width: 16px;
              height: 3px;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              margin: auto;
              background-color: $panel-heiglight-text;
              // transform: rotate(45deg);
              border-radius: 4px;
            }

            &::after {
              content: "";
              position: absolute;
              height: 16px;
              width: 3px;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              margin: auto;
              background-color: $panel-heiglight-text;
              // transform: rotate(45deg);
              border-radius: 4px;
            }
          }
        }
        .btn-container{
          text-align: center;
        .btn-upgrade{
          background: $upgrade-btn-bg;
          color: #fff;
          border: none;
          padding: 6px 17px;
          margin: auto;
          font-size: 14px;
          line-height: 30px;
          box-shadow: none;
        }
      }
      }

      .InfiniteCarouselArrowNext {
        left: auto;
        right: -10px;
        box-shadow: none;
        height: 249px;
        background-color: rgba(14 ,59 ,122, 0.25);
    }
    .InfiniteCarouselArrowPrev {
      left: -10px;
      right: auto;
      box-shadow: none;
      height: 249px;
      background-color: rgba(14 ,59 ,122, 0.25);
  }
  .InfiniteCarouselArrowIcon {
    border-color: solid #7190ab;
}
    }
  }
}

.customConfirm {
  width: 400px;
  height: 200px;
  background: #FFFFFF;
  mix-blend-mode: normal;
  backdrop-filter: blur(4px);
  border-radius: 12px;

  .title {
    @include subBodyBackgroundTitle();
  }

  .description {
    font-weight: bold;
    font-size: 22px;
    color: #0056A3;
    text-align: center;
    margin-top: 2rem;
  }

  .actionButton {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    & button {
      border: 1px solid #175AAF;
      box-sizing: border-box;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      border-radius: 10px;
      font-weight: bold;
      font-size: 13px;
      color: #0056A3;
      padding: 5px 10px;
      margin: 1rem;

      &:hover {
        background: #175AAF;
        color: #FFFFFF;
      }
    }
  }

}