.promo {
  border-radius: 10px;
  background: $primary-color-white;
  box-shadow: 0 0 6px #cee1ed;
  position: relative;
  @include customAlertViewTemp();
  .items-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0.8rem;
    gap: 0.8rem;
    @include screen("medium") {
      flex-direction: column;
    }

    .item-box {
      border: 1px solid $panel-blue-border-color;
      box-sizing: border-box;
      border-radius: 8px;
      width: calc(50% - 0.4rem);
      @include screen("medium") {
        width: 100%;
      }
      .title {
        font-size: 16px;
        font-weight: bold;
        margin: 10px 0 0 10px;
      }

      .item {
        display: flex;
        flex-direction: row;
        width: auto;
        height: auto;
        justify-content: start;
        align-items: center;
        padding: 10px;
        position: relative;

        .details {
          margin: 0 10px;
          align-self: start;
          width: 100%;
          .status {
            font-size: 16px;
            font-weight: bold;
          }
          .valid {
            font-size: 14px;
            margin-top: 5px;
          }
        }

        .image-container {
          .imageView {
            width: 80px;
            border-radius: 10px;
          }
        }

        .button-container {
          align-self: start;
          justify-self: end;
          .btn-mobitel {
            width: initial;
            height: initial;
            padding: 8px 15px;
            background: #1979d1;
            box-shadow: none;
            border-radius: 7px;
            font-style: normal;
            border: none;
            font-weight: normal;
            font-size: 15px;
            text-align: center;
            color: #ffffff;
            transition: all 0.3 ease;
            display: block;

            &:active {
              transform: scale(0.97, 0.97);
              transition: all 0.3 ease;
            }
          }
        }
      }
    }
  }
}
