button {
  display: inline-block;
  border-radius: 10px;
  outline: none;
  border: none;
  // border: 1px solid #dbe8f1;
  // box-shadow: 0px 2px 2px #d0e2ed;
  border: $button-border;
  box-shadow: $button-box-shadow;

  &.small {
    width: 54px;
    height: 54px;
    margin: 5px;

    img {
      width: 32px;
      height: 32px;
    }
  }

  &.medium {
    width: 88px;
    height: 40px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 600;
    line-height: 38px;
    &.noline {
      line-height: 0;
    }
    &.long {
      width: 94px;
    }
    &.highLong {
      width: 100px;
    }
  }

  &.noAccountButton {
    height: 100%;
    max-width: 100%;
    & img {
      width: 100%;
      height: 100%;
    }
  }

  &.blue {
    // background: $primary-color-blue;
    background: #1977d1;
    color: $primary-color-white;
          box-shadow: none;
          border: none;
          border-radius: 8px;

    &:hover {
      background: $primary-color-darkBlue;
    }

    &:disabled {
      background: $primary-color-darkBlue;
      cursor: not-allowed;
    }

  }

  &.white {
    background: $primary-color-white;
    color: $primary-color-blue;

    &:hover {
      background: $primary-color-darkWhite;
    }
  }
}
