.services {
  grid-area: services;
  border-radius: 10px;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  background: $panel-blue-bg;
  box-shadow: $panel-blue-box-shadow;
  box-shadow: $panel-blue-border;
  color: $primary-color-white;

  @include screen(large) {
    // display: none;
  }

    .service-item-wrapper{
      padding: 4px;
    flex: 1 0 26%;

    box-sizing: border-box;  
      @include screen("large") {
      flex: 1;
      margin: 0;
    }
    @include screen("medium") {
      flex: 1;
      margin: 0;
    }
    @include screen("small") {
      flex: 0 0 33.33%;
      margin: 0;
    }


  .service-item {
    background: $main-btn-bg;
    color: $main-btn-text-color;
    text-align: center;
    position: relative;
    width: auto;
    padding: 10px 4px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    min-height: 78px;
 
    a {
      text-decoration: none;
    }

    & img {
      //width: 52px;
      height: 31px;

    }

    .item-label {
      font-size: 13px;
      font-weight: 600;
    color: #a4c4eb;
      margin-top: 6px;
      margin-bottom: 0;
    }

    .item-more {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 0px;
      color: #a4c4eb;

      p {
        margin-bottom: 0px;
        margin-right: 4px;
      }
    }

    &.selected {
      transition: all 200ms linear;
      background: #14adf5;
      border: none;

      .item-label {
        transition: all 100ms linear;
        color: #fff;
      }
    }

    &:disabled {
      opacity: 0.5;
      filter: grayscale(1);
    }
  }
  }

}
