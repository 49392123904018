.newService {
  width: 100%;
  border-radius: 10px;
  background: $panel-bg;
  padding: 10px;
  // box-shadow: 0 0 6px #cee1ed;
  position: relative;
  @include screen("small") {
    padding: 4px;
  }
  .title {
    @include subBodyBackgroundTitle();
  }

  @include customAlertView();


  .formViews {
    width: 100%;
    height: auto;
    border: 1px solid $form-border;
    box-sizing: border-box;
    border-radius: 7px;
    max-width: initial;
    background: $panel-bg;
    color: $primary-color-white;
    padding: 10px;
    // margin: 2rem auto;
    @include screen("small") {
     //max-width: 200px;
      //margin-right: 10px;
    }

    .radioButtons {
      width: 100%;
      display: flex;
      margin: auto;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-top: 1rem;


      > div {
        display: flex;
        margin: 0 8px;

        > input {
          width: 20px;
          min-width: 33px;
          height: 20px;
        }

        > label {

          color: $dark-text-white;
          cursor: pointer;
        }
      }

    }

    .headerTitle {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      color: $dark-text-white;
    }

    .fields {
      margin: 2rem -0.6rem .6rem  -0.6rem;

      > div {
        // display: flex;
        align-items: center;
        padding: 0.8rem 0.6rem;
        box-sizing: border-box;
        width: 50%;
        float: left;


        @include screen("small") {
          width: 100%;
        }

        > label {
          // position: absolute;
          display: block;
        }

        > div {
          position: absolute;
          margin-left: 100px;
          margin-top: -60px;
          color: red;
          font-size: 14px;
        }

        > input {
          padding-left: 20px;

          &[type='radio'] {
            background: #2FD664;
            width: 20px;
            height: 20px;
            padding: 5px;
            margin-left: 90px;
          }
        }

        .animation {
          animation-name: success;
          animation-duration: 2s;
        }

      }
    }

    .checkBoxHeader {
      font-size: 14px;
      color: $dark-text-white;
      font-weight: 600;
    }

    .service-types {
      .radioButtons {
        justify-content: left;
        margin-top: 10px;

        > div {
          margin: 0 10px;

          input {
            min-width: 20px;
            margin-right: 6px;
          }

          &:first-child {
            margin-left: 0px;
          }
        }
      }
    }

    .lineBar {
      margin: 1rem 0;
      border: 1px solid #C8DBE8;
    }

    .submit {
      margin: 1rem 0 0 0;
      display: flex;
      justify-content: flex-end;

    }

    .fiber {
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      color: $dark-text-white
    }

  }
}