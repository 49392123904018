.bill {
  @include subBodyBroadband();

  .body-container {
    padding: 10px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: $dark-title-higlight;
      padding: 2px 0 10px 2px;
    }
    .wrapper {
      width: 100%;
      height: auto;
      border: 1px solid $panel-blue-border-color;
      box-sizing: border-box;
      border-radius: 7px;
      padding: 15px;
    }
  }

  .title {
    @include subBodyBackgroundTitle();
  }

  @include customAlertViewTemp();
  .switch-button {
    background: #c4c4c4;
    border-radius: 10px;
    overflow: hidden;
    width: 263px;
    line-height: 26px;
    height: 36px;
    text-align: center;
    font-size: 13px;
    letter-spacing: 1px;
    position: relative;
    padding-right: 110px;

    &:before {
      content: "Bill History";
      font-size: 13px;
      color: $primary-color-white;
      font-weight: bold;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 141px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      pointer-events: none;
    }

    &-checkbox {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;

      &:checked + .switch-button-label:before {
        transform: translateX(130px);
        transition: transform 300ms linear;
      }

      & + .switch-button-label {
        position: relative;
        padding: 5px 0;
        display: block;
        user-select: none;
        pointer-events: none;

        &:before {
          content: "";
          background: $primary-color-blue;
          height: 100%;
          width: 95%;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 8px;
          transform: translateX(0);
          transition: transform 300ms;
        }

        .switch-button-label-span {
          position: relative;
        }
      }
    }
  }

  .tab-button {
    background: #c4c4c4;
    border-radius: 10px;
    overflow: hidden;
    width: fit-content;
    line-height: 26px;
    text-align: center;
    font-size: 13px;
    letter-spacing: 1px;
    color: $primary-color-white;
    height: 36px;

    > button {
      background: #c4c4c4;
      color: $primary-color-white;
      font-weight: bold;
      float: left;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 7px 16px;
      transition: 0.3s;

      &.active {
        background: $primary-color-blue;
      }
    }
  }

  .billDetails {
    margin: auto;

    .totalPayable {
      margin-top: 20px;
      .blueArea {
        // background: #E1F0FA;
        border-radius: 10px;
        // padding: 20px;

        .header {
          font-size: 16px;
          color: $dark-title-white;
          margin-bottom: 10px;
          padding: 14px;
          font-weight: 600;
          background-color: #4ba2c4;
          border-radius: 10px;
          background-color: #4ba2c4;
        }

        .details {
          font-size: 16px;
          color: $dark-title-white;
          margin-bottom: 10px;
          padding: 14px;
          background-color: $table-row-color;
          border-radius: 10px;
          border: 1px solid $table-row-border-color;

          .oneRow {
            display: flex;
            justify-content: space-between;

            .last-payment {
              font-weight: 600;
            }
            .last-payment-value {
              font-weight: 600;
              color: $dark-title-white;
            }
            .month-end {
              color: #957833;
            }
          }
        }

        .submit {
          text-align: center;
          > button {
            //background: #FFFFFF;
            //box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            //border-radius: 5px;
            //font-weight: normal;
            //font-size: 14px;
            //line-height: 19px;
          }
        }
      }
    }
  }

  .select {
    width: 100%;
    padding: 5px 20px;
    margin: 5px 0;
    display: inline-block;
    border: 1px solid $select-text-border-color;
    border-radius: 7px;
    box-sizing: border-box;
    color: $select-text-color;
    background-color: $select-bg-color;
  }

  .agreeCondition {
    margin-top: 2rem;
    display: flex;
    align-content: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    color: #565656;

    strong {
      color: #60c4ff;
      //font-weight: normal;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .history {
    margin-top: 20px;

    .details {
      background-color: $table-row-color;
      border: 1px solid $table-row-border-color;
      border-radius: 7px;
      margin: auto;
      margin-bottom: 10px;
      padding: 6px;

      &.singleLine {
        display: flex;
        align-items: start;
        justify-content: space-between;
        margin-left: 10px;
        margin-right: 10px;
        > div {
          min-width: 120px;
        }
      }

      .firstRow {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        font-size: 15px;
        color: $dark-title-white;
        margin: 0.5rem;
        > button {
          background: #561d7e;
          border-radius: 28px;
          font-size: 14px;
          line-height: 16px;
          color: $dark-title-white;
        }
      }

      .secondRow {
        font-size: 13px;
        color: $dark-title-white;
        margin: 0.5rem;
        min-width: 120px;
      }

      .thirdRow {
        font-weight: 600;
        font-size: 16px;
        color: $dark-title-higlight;
        margin: 0.5rem;
      }
    }
  }
}
