.subscribeDetailReport {
  @include subBodyBroadband();
  margin-top: 0.6rem;

  .body-container{

    padding: 10px;

    .title{

      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: $dark-title-white;
      padding: 2px 0 10px 2px;
    }
    .wrapper{
      width: 100%;
      height: auto;
      border: 1px solid #C8DBE8;
      box-sizing: border-box;
      border-radius: 7px;
      padding: 15px;
    }
  }

  @include customAlertViewTemp();
  .choosePeriod {
    .selectPeriod {
      display: flex;
      justify-content: center;

      .box {
        width: 150px;
        height: auto;
        margin: 2rem;
        text-align: center;


        &.select {
          border: 2px solid #0056A3;
          box-sizing: border-box;
          border-radius: 7px;
        }

        > img {
          width: 130px;
          height: 120px;
          margin: 6px;

        }
      }
    }

    .discription {
      background: $common-container-bg;
      border: 1px solid $common-containe-border-color;
      color: $common-container-text-color;
      border-radius: 7px;
      width: 80%;
      margin: auto;
      display: flex;
      align-items: center;
      align-content: center;

      .leftSide {
        width: 60%;
        font-size: 18px;
        color: $common-container-text-color;
        padding: 10px;
      }

      .rightSide {
        width: 40%;
        margin-right: 0;

        > button {
          margin: 1rem;
          padding: 0.5rem;
          background: $button-bg;
          min-width: 90px;
          //min-height: 90px;

          > svg {
            fill: #fcfcfc;
            width: 50px;
            height: 50px;
          }

          > div {
            font-weight: bold;
            margin: 2px 0 0 0;
            color: $dark-text-white;
          }

          &.selectedType {
            background:#1480FF ;
            > svg {
              fill: #f7fbff;
            }
            > div {
              color: #FFFFFF;
            }
          }
        }
      }
    }

    .actionButton {
      height: 70px;

      > button {
        background: #175AAF;
        border: 1px solid #175AAF;
        box-sizing: border-box;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 5px 10px;
        float: right;
        margin-right: 120px;
        margin-top: 20px;
        color: #FFFFFF;

      }
    }

  }

  .confirmPayment {
    .topDetails {
      font-weight: 600;
      font-size: 23px;
      color: #60c4ff;
      text-align: center;
      margin-top: 30px;
    }

    .price {
      font-weight: 600;
      font-size: 18px;
      //color: #707070;
      color: $panel-text-color;
      text-align: center;

    }
    .details {
      font-weight: 600;
      font-size: 23px;
      color: #0056A3;
    }

    .agreeCondition {
      margin-top: 2rem;
      display: flex;
      align-content: center;
      justify-content: center;
      font-weight: bold;
      font-size: 18px;
      color: #565656;
      strong {
        color: #60c4ff;
        //font-weight: normal;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .actionButton {
      display: flex;
      justify-content: center;
      margin-top: 2rem;

      & button {
        border: 1px solid #175AAF;
        box-sizing: border-box;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        border-radius: 10px;
        font-weight: bold;
        font-size: 13px;
        color: #0056A3;
        padding: 5px 10px;
        margin: 1rem;

        &:hover {
          background: #175AAF;
          color: #FFFFFF;
        }

        &:disabled {
          background: #af6b17;opacity: 0.5;
          filter: grayscale(1);
          color: #FFFFFF;
        }
      }
    }
  }

}
