.userInformation {
  width: 100%;
  height: 100%;
  //min-height: 540px;
  margin: auto;
  position: relative;

  .form-container {
    .form-inputs-container {
      width: 50%;
      float: left;
      @include screen("medium") {
        width: 100%;
        padding-right: 0px;
      }
    }

    input {
      background-color: white;
      color: black;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #363636;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #363636;
      }
      &:-webkit-autofill {
        -webkit-text-fill-color: #363636 !important;
      }
    }
    select {
      background-color: white !important;
      color: black !important;
      option {
        color: black !important;
      }
    }
    label {
      color: #363636;
    }

    .autoCom {
      width: 100%;

      .MuiFormControl-root {
        .MuiInputBase-root {
          padding-right: 39px;
          height: 40px;
          font-size: 14px;
          color: $input-color;
          input {
            padding: 0px;
          }

          fieldset {
            border-color: $input-border;
            border-radius: 7px;
          }

          button {
            position: absolute;
            right: -3px;
            top: -2px;
            height: 32px;
            padding: 0 10px;
            background: #8a99a6;
            box-shadow: none;
            border-radius: 6px;
            border: none;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            text-align: center;
            color: #ffffff;
            width: 30px;
          }
        }
      }

      &.mobile-verify {
        input {
          background-color: inherit;
        }
      }
    
      &.not-mobile-verify {
        input {
          background-color: #e0e0e0;
        }
      }
    }
  }

  .userInfoForm {
    width: 100%;
    margin: auto;
    // border: 1px solid #ececec;
    /* box-shadow: 0 0 1px #e2e2e2; */
    box-sizing: border-box;
    border-radius: 10px;
    padding: 2rem;
    @include screen("medium") {
      padding: 1rem;
    }

    .packages-container {
      display: flex;
      flex: 1;
      gap: 20px;
      margin-bottom: 20px;
      @include screen("medium") {
        flex-direction: column;
      }
      .package-container {
        border-radius: 12px;
        background: #f9f9f9;
        width: 50%;
        @include screen("medium") {
          width: 100%;
        }
        // font-family: Poppins;
        padding: 15px;
        .mini-title {
          color: #787878;
          font-size: 12px;
          font-weight: 400;
        }
        .main-title {
          color: #363636;
          font-size: 24px;
          font-weight: 600;
        }
        .details-title {
          color: #007eff;
          font-size: 14px;
          font-weight: 600;
        }
        .package-details {
          color: #363636;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  .otpInput {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(102, 107, 134, 0.1);
    mix-blend-mode: normal;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
  
    .otpInputBody {
      width: 90%;
      max-width: 400px;
      background: $panel-blue-bg;
      border-radius: 8px;
      padding: 1rem;
  
      .title {
        @include subBodyBackgroundTitle();
        text-align: center;
        margin-bottom: 1rem;
  
        > span {
          float: right;
          margin: 10px;
          &:hover {
            cursor: pointer;
          }
        }
      }
  
      .inputArea {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
  
        input {
          padding: 8px;
          font-size: 16px;
          width: 40px;
        }
      }
  
      .resend {
        color: $dark-title-white;
        cursor: pointer;
  
        &:hover {
          text-decoration: underline;
        }
      }
  
      .actions {
        display: flex;
        justify-content: space-around;
      }
  
      @media (max-width: 600px) {
        padding: 0.5rem;
  
        .title {
          font-size: 18px;
        }
  
        .inputArea {
          input {
            width: 10px;
            padding: 0;
          }
        }
      }
    }
  }
  
  .container-footer {
    border-top: 0px;
  }
}

.locationInformation {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;

  .bodyView {
    display: flex;
    min-height: 365px;

    .form-container {
      display: flex;
      width: 100%;
      min-height: 480px;
      margin: auto;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 2rem;
      @include screen("medium") {
        display: block;
        padding: 1rem;
      }
      &.noPad {
        padding: 0 2rem;
      }

      .form-inputs-container {
        width: 50%;
        padding: 0 20px 20px 0;
        box-sizing: border-box;
        @include screen("medium") {
          width: 100%;
          padding-right: 0px;
          padding-left: 0px !important;
        }

        .input-container {
          .autoCom {
            width: 100%;

            .MuiFormControl-root {
              .MuiInputBase-root {
                padding-right: 39px;
                height: 40px;
                font-size: 14px;
                color: $input-color;
                background-color: #0a3065;
                input {
                  padding: 0px;
                }

                fieldset {
                  border-color: $input-border;
                  border-radius: 7px;
                }

                button {
                  position: absolute;
                  right: -3px;
                  top: -2px;
                  height: 32px;
                  padding: 0 10px;
                  background: #8a99a6;
                  box-shadow: none;
                  border-radius: 6px;
                  border: none;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 14px;
                  text-align: center;
                  color: #ffffff;
                  width: 30px;
                }
              }
            }
          }
        }

        .map-container {
          //width: 50%;
          padding: 0;
          height: 380px;
          //box-sizing: border-box;
          position: relative;
          @include screen("medium") {
            height: 365px;
            padding-bottom: 32px;
          }
          > p {
            margin: 5px 0;
            font-weight: 600;
            font-size: 18px;
          }
          .btn-remove {
            cursor: pointer;
            position: absolute;
            //right: 5px;
            //top: 5px;
            //right: 9px;
            top: 50px;
            right: 225px;

            z-index: 50;

            width: 22px;
            height: 22px;
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAslJREFUOI2tlU1IVFEYhp/v3KuVUVEQidrCRSpmUGGUUnBnk8SQU6QtCiP6I1pJRkGbVkHEUNGqKMMkIrDFaDN7iYLJIgOp6Q+CMgmKMsoU5s75WpRDM96xH3uX5z3fc777fpx7hAJqatq7yHUzW4BmEVsDUvHD0WGQFNCnmo4lEtc/BdVL/kJra+uciYmSw6ocBeYXOhhAhM/W6umSkvGzPT094wXBzc17yqzNxIA10wED9EhEI7dudb+eAt60aWeF47hJoPwvoZMaBl0Xj3e/BTDw4/Mdx+2dARSgQlVinrd7dhb87dvcI8DqGUABEKF+3jzaAaSpae+ioiL/FXmDqq2toq6umps3E1hrcwDGGFpawgwNPSWVepEP/2xtutIUF/tb86EAdXXVtLVto6PjAMaYHGhHxwHa2raxYkXNlK5VWWBMccRZtmzVCRGm7EilXlJauhjPa2Dp0jKSyYeICO3t+/C8Bu7cGaCz8waqGpSKuiK6PMix1nLu3GUAQqFGRIR0Op2FRqMXyWQyQaWA1rpAaQE3CxcRPK8BgLt37/8GCiDlZhq3UNGfbLIuMAJUBbnGmF8yvY/v+3heA6oHiUYvTNf1iAs8CQJPQkOhxmymqoqqEgo1AhSEi+gTp6pqZQkQyTe3b99MJLKR27eTRKMXsdaiqty7N0h5+RI2bFhLOu3z+PHzgIbllFNZWf/ade1BYHZOSNby7t17Ojtv5FwQVSWZfIjv+wwNPeXDh4/51FHXlUMCEA7vOi4iJwsF9pc6Fo9fPW0AxsbMGVUe/AfowNevch5+/oT6+7smRHQLMDwD6BtjnK39/V0TWTBAPN791hhnLTDwD9BBEV3f13dlZHLB+dV99mzwS1nZqmuzZsmYCGvIG2iARoETmcz8/YnEpZwpFrxG4fCOhcYUR1Q1AtQAPx9ThoGUqvYWFZneWKxrNKj+O4gjF5HzhvjDAAAAAElFTkSuQmCC");
            background-repeat: no-repeat;
            background-position: center;

            transition: all 0.3 ease;
            @include set-btn-click-animation(0.97);
          }

          .search {
            position: absolute;
            z-index: 10;
            width: 300px;
            margin: 14px;

            @include screen("medium") {
              position: absolute;
              z-index: 10;
              width: 76%;
              /* margin: 11px; */
              margin-top: 11px;
              margin-left: 6px;
            }
          }

          > div {
            width: 100%;
            height: 100%;
            margin: 0px;
            padding: 0px;
            position: relative;
            border: 1px solid #e4e4e4;
            border-radius: 10px;

            > div {
              border-radius: 4px;
            }
          }
          .mask {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            z-index: 100;
          }
        }
      }
    }

    .attempt-info {
      width: fit-content;
      text-align: center;
      margin-top: 16px;

      color: #f5f5f6;
      font-size: 13px;
      font-weight: 600;
      margin: auto;
      padding: 4px 8px;
      background-color: #79a7e6;
      border-radius: 20px;
      border: 1px solid #80a1cf;
      p {
        margin-right: 2px !important;
        color: #d76200;
      }
    }

    // > div {
    //   flex-grow: 1;
    //   flex-basis: 50%;
    //   margin: 0.6rem;
    //   border: 1px solid #ececec;
    //   box-sizing: border-box;
    //   border-radius: 10px;
    //   padding: 30px;
    // }
  }

  .autoCom {
    width: 100%;

    .MuiFormControl-root {
      .MuiInputBase-root {
        padding-right: 39px;
        height: 40px;
        font-size: 14px;
        color: $input-color;

        input {
          padding: 0px;
        }

        fieldset {
          border-color: $input-border;
          border-radius: 7px;
        }

        button {
          position: absolute;
          right: -3px;
          top: -2px;
          height: 32px;
          padding: 0 10px;
          background: #8a99a6;
          box-shadow: none;
          border-radius: 6px;
          border: none;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          text-align: center;
          color: #ffffff;
          width: 30px;
        }
      }
    }
  }

  .successFeasibility {
    width: 50%;
    background: #e7f0f6;
    border: 2px solid #c8dbe8;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 0 auto 1rem auto;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    //vertical-align: middle;
    color: #000000;
    padding: 1rem;

    > strong {
      color: #2fd664;
    }
  }

  .noSuccessFeasibility {
    width: 50%;
    background: #e7f0f6;
    border: 2px solid #c8dbe8;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 0 auto 1rem auto;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    //vertical-align: middle;
    color: #000000;
    padding: 1rem;

    > strong {
      color: #d62f2f;
    }
  }

  .attempt-info-lte {
    width: fit-content;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
    color: #f5f5f6;
    font-size: 13px;
    font-weight: 600;

    padding: 4px 8px;
    background-color: #79a7e6;
    border-radius: 5px;
    border: 1px solid #80a1cf;
    p {
      margin-right: 2px !important;
      color: #d76200;
    }
  }

  .ltecheckboxlbl {
    color: #c9e0f5;
  }

  .coverageNote {
    width: 100%;
    background: #e7f0f6;
    border: 2px solid #c8dbe8;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 0 auto 1rem auto;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    //vertical-align: middle;
    color: #000000;
    padding: 1rem;

    > strong {
      color: #0056a3;
      cursor: pointer;
    }
  }
}
