.pdfView {
  width: 595.28px;
  min-height: 1000px;
  position: absolute;
  //margin: auto;
  margin-top: -5000px;
  padding: 1rem;
  //position: relative;
  background: #FFFFFF;
  z-index: -1;

  label {
    color: black;
    font-weight: normal;
  }

  .header {
    font-weight: bolder;
    max-height: 100px;
    display: flex;

    > img {
      width: 20%;
    }

    .titles {
      display: flex;
      flex-direction: column;
      width: 70%;
      justify-content: center;
      padding: 0 20px;
      //margin: 0 1rem;
      .firstRow {
        font-weight: bold;
        font-size: 20px;

      }

      .secondRows {
        font-size: 14px;
        color: black;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .box {
      width: 10%;
      margin: 0 0 0 4rem;
      height: 60px;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 5px;
      float: right;
    }
  }

  .underline {
    width: 100%;
    border: 1px solid #000000;
    box-sizing: border-box;
    margin: 1rem 0;
  }

  .detailsView {
    .checkboxSet {
      display: flex;

      .others {
        width: 30%;
        display: flex;
        align-items: center;
        >label {
          margin: 0;
          width: 50%;

        }
        > div {
          width: 50%;
          height: 23px;
          //background: black;
          border: 1px solid  #000000;
          box-sizing: border-box;
          border-radius: 0;
        }
      }

      .checkbox {
        >label {
          margin: 0;

        }
        width: 15%;
        display: flex;
        align-items: center;
      }
    }

    .oneSection {
      .head {
        font-weight: bold;
      }

      .field {
        display: flex;
        align-items: center;
        margin-top: 3px;
        > label {
          margin: 0;
          width: 30%;


        }
        > div {
          border: 1px solid  #000000;
          box-sizing: border-box;
          border-radius: 0;
          height: 23px;
        }
      }
    }

    .boxSection {
      border: 1px solid  #000000;
      box-sizing: border-box;
      border-radius: 0;
      margin-top: 4px;
      > div {
        margin-top: 3px;
        display: flex;
        .field {
          display: flex;
          align-items: center;
          margin-top: 3px;

          > label {
            margin: 0;

          }
          > div {
            border: 1px solid  #000000;
            box-sizing: border-box;
            border-radius: 0;
            height: 20px;
          }
        }
      }

    }

    .madeOfCollection {
      border: 1px solid  #000000;
      box-sizing: border-box;
      border-radius: 0;
      margin-top: 4px;
      padding: 4px;
      .head {
        font-weight: bold;
      }

      .tbl {
        width: 90%;
        margin: 3px auto;
        display: flex;
        > div {
          width: 25%;
          text-align: center;
          display: flex;
          flex-direction: column;
          > div {
            width: 80%;
            margin: auto;
            height: 25px;
            border: 1px solid  #000000;
            box-sizing: border-box;
            border-radius: 0;
          }
        }
      }
    }

    .packageDetails {
      border: 1px solid  #000000;
      box-sizing: border-box;
      border-radius: 0;
      margin-top: 4px;
      padding: 4px;

      .packs {
         > div {
           border: 1px solid  #000000;
           box-sizing: border-box;
           border-radius: 0;
           width: 150px;
           font-size: 14px;
           height: 23px;
         }
      }
    }

    .comment {
      border: 1px solid  #000000;
      box-sizing: border-box;
      border-radius: 0;
      margin-top: 4px;
      padding: 4px;
      height: 50px;
    }

    .agreement {
      .discription {
        font-size: 12px;
      }
    }
  }



}