.createComplaint {
  //width: 100%;
  padding: 10px;

  @include subBodyBroadband();
  position: relative;

  .title {
    display: flex;
    a {
      text-decoration: none !important;
    }

    @include subBodyBackgroundTitle();
    // font-family: Open Sans;
    // text-decoration: none;
    .backButton {
      width: 60%;
      padding: 7px;

      // font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 25px;


      color: #707070;
    }
  }


  @include customAlertViewTemp();

  .cardCeateComplaint {
    // margin:20px;
    padding: 10px;

    border: 1px solid $panel-blue-border-color;
    box-sizing: border-box;
    border-radius: 7px;

    .rowCeateComplaint {

      display: flex;
      margin: 5px;
      column-gap: 1rem;
      @include screen("medium") {
        flex-direction: column;
        column-gap: 1rem;
      }
      > div {
        width: 100%;
        height: 100%;
      }

      .createComplaintLabel {
        text-align: left;
        // font-family: Open Sans;
        font-style: normal;
        color: #434343;

      }

      .createComplaintDropDown {
        > select {
          width: 100%;
          padding: 5px 20px;
          margin: 5px 0;
          display: inline-block;
          border: 1px solid $select-text-border-color;
          border-radius: 7px;
          box-sizing: border-box;
          color: $select-text-color;
          background-color: $select-bg-color;
        }
      }

      .createComplaintInput {
        //background: #FFFFFF;
        // border: 1px solid #C8DBE8;
        box-sizing: border-box;
        border-radius: 7px;

        > input {
          width: 100%;
          border: 1px solid $input-border;
        }

        > textarea {
          width: 100%;
          height: 90px;
          padding: 12px 20px;
          box-sizing: border-box;
          border: 1px solid $input-border;
          border-radius: 4px;
          background-color: $input-bg;
          color: $input-text-color;
          font-size: 16px;
          resize: none;
          outline-color: $input-border;
        }


      }

      .map-container {
        width: 100%;
        padding: 0;
        height: 75%;
        //box-sizing: border-box;
        position: relative;

        > p {

          font-weight: 600;
          font-size: 14px;
          color: $dark-title-white;
          margin-bottom: 14px;

        }

        .search {
          position: absolute;
          z-index: 10;
          width: 300px;
          margin: 11px;
          @include screen("medium") {
            position: absolute;
            z-index: 10;
            width: 76%;
            /* margin: 11px; */
            margin-top: 11px;
            margin-left: 6px;
          }
        }

        > div {
          width: 100%;
          height: 100%;
          margin: 0px;
          padding: 0px;
          position: relative;
          border: 1px solid #e4e4e4;
          border-radius: 10px;

          > div {
            border-radius: 4px;
          }
        }
      }

      .col-item {
        width: 50%;

        &:first-child {
          padding-right: 10px;
        }

        &:last-child {
          padding-left: 10px;
        }
      }

    }

    .createComplaintButtonContanner {
      text-align: right;
      padding: 10px 4px;
      @include screen("medium") {
        margin-top: 2rem;
      }
      .createComplaintButton {
        background: #1977d1;
        color: white;
        box-shadow: none;
        border: none;
        border-radius: 8px;
        height: 40px;
        box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
        font-size: 14px;
        font-weight: 600;
        line-height: 38px;
        padding: 0 14px;

      }
    }

  }

}

.masked {
    position: relative;
}

.masked::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1;
}
