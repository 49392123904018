.paymentBody {
  margin-top: 0.6rem;
  box-shadow: 0 0 6px #cee1ed;
  border: 1px solid #C8DBE8;
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 450px;
  background: $primary-color-white;
  position: relative;

  .body-container{

    padding: 10px;

    .title{

      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: #1448B6;
      padding: 2px 0 10px 2px;
    }
    .wrapper{
      width: 100%;
      height: auto;
      border: 1px solid #C8DBE8;
      box-sizing: border-box;
      border-radius: 7px;
      padding: 15px;
    }
  }

  @include customAlertViewTemp();

  @include TermCondistion(10px, 10px);
  .confirmPaymentBody {
    .gbAmount {
      color: #1480FF;
      font-weight: 800;
      font-size: 73px;
      margin: 0 auto;
      max-height: 90px;
      text-align: center;

    }

    .price {
      text-align: center;
    }

    .agreeCondition {
      margin-top: 2rem;
      display: flex;
      align-content: center;
      justify-content: center;
      font-weight: bold;
      font-size: 18px;
      color: #565656;

      strong {
        color: #60c4ff;
        //font-weight: normal;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .actionButton {
      display: flex;
      justify-content: center;
      margin-top: 2rem;

      & button {
        background: #aaaaaa;
        color: #fff;
        box-shadow: none;
        border: none;
        border-radius: 8px;
        height: 40px;
        box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
        font-size: 14px;
        font-weight: 600;
        line-height: 38px;
        padding: 0 16px;
        margin:0 10px;
        &:hover {
          background: #175AAF;
          color: #FFFFFF;
        }
&.buttonClass{
        background: #1977d1;

}
        &:disabled {
          background: #af6b17;opacity: 0.5;
          filter: grayscale(1);
          color: #FFFFFF;
        }
      }
    }

    .actionButtonGift {
      display: flex;
      justify-content: center;
      margin-top: 2rem;

      & button {
        border: 1px solid #175AAF;
        background-color: #FFFFFF;
        box-sizing: border-box;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        border-radius: 10px;
        font-weight: bold;
        font-size: 13px;
        color: #0056A3;
        padding: 5px 10px;
        margin: 1rem;

        &:hover {
          background: #175AAF;
          color: #FFFFFF;
        }

        &:disabled {
          background: #af6b17;opacity: 0.5;
          filter: grayscale(1);
          color: #FFFFFF;
        }
      }
    }

  }

  .selectPaymentType {

    .upperSection {
      display: flex;
      justify-content: center;
      align-items: center;
      @include screen("medium") {
       display: block;
          }
      .details {
        margin: 2rem;

        .gbAmount {
          color: #1480FF;
          font-weight: 800;
          font-size: 73px;
          margin: 0 auto;
          max-height: 90px;
          text-align: center;

        }

        .price {
          text-align: center;
        }
      }

      .typeButtons {
        margin: 2rem;
        @include screen("medium") {
          margin:1rem;
             }
        > button {
          margin: 1rem;
          padding: 0.5rem;
          background: $button-bg;
          min-width: 90px;
          //min-height: 90px;

          > svg {
            fill: #fcfcfc;
            width: 50px;
            height: 50px;
          }

          > div {
            font-weight: bold;
            margin: 2px 0 0 0;
            color: $dark-text-white;
          }

          &.selectedType {
            background:#1480FF ;
            > svg {
              fill: #f7fbff;
            }
            > div {
              color: #FFFFFF;
            }
          }
        }
      }
    }


    .upperSectionGift {
      display: flex;
      justify-content: center;
      align-items: center;

      .details {
        margin: 2rem;

        .gbAmount {
          color: #1480FF;
          font-weight: 800;
          font-size: 73px;
          margin: 0 auto;
          max-height: 90px;
          text-align: center;

        }

        .price {
          text-align: center;
        }
      }

      .typeButtonsGift {
        margin: 2rem;

        > button {
          border: 1px solid #175AAF;
          background-color: #FFFFFF;
          margin: 1rem;
          padding: 0;


          > img {
            width: 100px;
            height: 100px;
          }
        }
      }
    }


    .actionButton {
      display: flex;
      justify-content: center;
      margin-top: 1rem;

      & button {
        background: #1977d1;
        color: #fff;
        box-shadow: none;
        border: none;
        border-radius: 8px;
        height: 40px;
        box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
        font-size: 14px;
        font-weight: 600;
        line-height: 38px;
        padding:0 16px;
        margin: 0 10px;

        &:hover {
          background: #175AAF;
          color: #FFFFFF;
        }
      }
    }

    .actionButtonGift {
      display: flex;
      justify-content: center;
      margin-top: 2rem;

      & button {
        border: 1px solid #175AAF;
        background-color: #FFFFFF;
        box-sizing: border-box;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        border-radius: 10px;
        font-weight: bold;
        font-size: 13px;
        color: #0056A3;
        padding: 5px 10px;
        margin: 1rem;

        &:hover {
          background: #175AAF;
          color: #FFFFFF;
        }
      }
    }

  }
}
