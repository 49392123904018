.redeemVoucher {
  margin-top: 0.6rem;
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 530px;
  height: auto;
  background: $panel-bg;
  box-shadow: $panel-box-shadow;
  border: $panel-border;
  padding: 10px;
  position: relative;

  .title {
    @include subBodyBackgroundTitle();
  }

  @include customAlertView();

  .redeemBorder {
    border: 1px solid $panel-blue-border-color;
    min-height: 530px;


    .voucherHeader {
      background: $common-container-bg;
      border: 1px solid $common-containe-border-color;
      color: $common-container-text-color;
      border-radius: 8px;
      text-align: center;
      padding: 50px;
      margin: 2rem auto;
      width: fit-content;

      @include screen('small') {
        padding: 20px;
        margin: 20px;
      }

      > h6 {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
      }
    }

    .voucherInput {
      background: $common-container-bg;
      border: 1px solid $common-containe-border-color;
      color: $common-container-text-color;
      border-radius: 8px;
      margin: 2rem auto;
      width: fit-content;
      justify-content: center;
      display: flex;
        @include screen('small') {
            padding: 20px;
            margin: 20px;
        }

      .vInputField {
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        color: #565656;

        > input {
          width: 90%;
          padding: 12px 20px;
          margin: 8px 0;
          box-sizing: border-box;
          border: none;
          border: $input-border;
        }

        //> input:focus {
        //  background-color: #dbf0e6;
        //  border-bottom: 2px solid #2292dd;
        //}

        > label {
          padding: 20px;
          font-size: 16px;
          width: 100%;
          @include screen('small') {
            padding: 6px;
          }

        }

      }

    }

    .submitRedeemVoucher {
      text-align: center;
      //

      .submitRedeemVoucherButton {
        background: linear-gradient(180deg, #1979D1 0%, #1658AD 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        padding: 10px;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }
}