 .voice-container {
   border-radius: 10px;
   background: $primary-color-white;
   box-shadow: 0 0 6px #cee1ed;
   display: grid;
   grid-auto-columns: 1fr 4fr;
   grid-template-areas: 'leftSide rightSide';
   grid-column-gap: 5px;
   min-height: 100%;


   .leftSide {
     display: flex;
     flex-direction: column;
     align-items: center;
     grid-area: leftSide;

     .buttonTab {
       position: relative;
       box-sizing: border-box;
       border-radius: 10px;
       flex-direction: column;
       width: 160px;
       height: 60px;
       margin: 0.8rem 2rem;
       text-align: center;
       text-decoration: none;
       color: #ffffff;
       font-weight: 600;
       z-index: 2;
      //  border: 1px solid #deebf3;
       background-color: #275596;
       cursor: pointer;
       display: flex;
       margin-bottom: 0px;

       &.blue {
         background: $primary-color-blue;
         color: $primary-color-white;
       }


       & span {
        position: absolute;
        display: block;
        height: 20px;
        top: 0;
        bottom: 0;
        right: -13px;
        margin: auto;
         color: $primary-color-blue;
       }     
       &:last-child{
        
        margin-bottom:  0.8rem;
        }
     }
   }

   .rightSide {
     border: 1px solid $panel-blue-border-color;
     box-sizing: border-box;
     border-radius: 8px;
     margin: 0.6rem 0.6rem 0.6rem 0;
     grid-area: rightSide;
     min-height: 250px;
     position: relative;

     &.loading {
       min-height: 500px;
     }

     .title{
       @include subBodyBackgroundTitle();
     }
     @include customAlertViewTemp();
     .myPackage {
       display: flex;
       align-items: center;
       //height: 100%;

       > div {
         width: 90%;
         margin: auto;

         .bodyView {
           width: 367px;
           height: 228px;
           margin: 0.6rem auto;
           background: linear-gradient(180deg, #0056A3 0%, #0B2A58 100%);
           border: 1px solid #C8DBE8;
           box-sizing: border-box;
           box-shadow: 0 4px 4px rgba(0, 0, 0, 0.09);
           border-radius: 5px;
           text-align: center;
           display: flex;
           flex-direction: column;
           justify-content: center;

           .firstRow {
             font-weight: bold;
             font-size: 16px;
             color: #FFFFFF;
           }

           .secondRow {
             font-weight: bold;
             font-size: 30px;
             color: #FFFFFF;
             margin: 10px;
           }

           .thirdRow {
             display: flex;
             font-weight: normal;
             font-size: 25px;
             color: #FFFFFF;
             justify-content: center;

             > div {
               &.blue {
                 color: #00B4EB;
                 margin-left: 20px;
               }
             }

           }
         }
       }

     }

     .callForwarding {
       position: relative;

       .content {
         //margin-
         margin: 0.6rem;
         .title{
           @include subBodyBackgroundTitle();
         }
         .card {
           width: 390px;
           border: 1px solid #305991;
           box-sizing: border-box;
           border-radius: 7px;
           margin: auto;

           .form {
             padding: 1rem;
           }

           > img {
             width: 64px;
             height: 64px;
             margin: auto;
           }
         }
       }
     }


   }
   @media screen and (max-width: 992px) {

     grid-template-areas: "leftSide"
      "rightSide";
     grid-auto-columns: initial;
     grid-template-columns: initial;
     //width: 100%;
     .leftSide {
       flex-direction: row;
       justify-content: center;
       width: auto;
       .buttonTab {
         margin: 0.8rem;
         min-width: 20%;
         & span {
           display: none;
         }
       }
     }
     .rightSide {
       margin: 0.6rem;
     }
   }
 }