.giftData {

  width: 100%;
  min-height: 430px;
  border-radius: 10px;
  background: $panel-bg;
  box-shadow: $panel-box-shadow;
  margin-top: .6rem;
  grid-template-areas: "navSide graphbody";
  padding: 10px 10px;
  box-sizing: border-box;
  position: relative;

  .title {
    @include subBodyBackgroundTitle();
  }

  @include customAlertView();



  .container-box {

    background: $gift-data-container-bg;
    color: $gift-data-text-color;
    margin-bottom: 10px;
    margin-left: 10%;
    border-radius: 10px;
    width: 80%;
    height: 20%;
    display: inline-block;
    padding: 10px;

    &:last-child{
      margin-bottom: 0;
    }
  }

  .text-wrapper {
    text-align: center;
    padding: 20px;

    p {
      margin-bottom: 5px;


      span {
        color: $dark-title-higlight;
      }
    }

    input {
      width: 50%;
      color: $input-text-color;
      background: $input-bg;
      border: none;
      border-bottom: 1px solid;
      border-bottom-color: $input-border;
      border-radius: 8px;
      display: inline-block;
      height: 36px;
      letter-spacing: 2px;
      text-align: center;
      margin-left: 10px;

      @include screen('small'){
        width: 100%;
      }

    }
  }

  button {
    padding: 15px;
    //float: right;
    background-color: #2573d8;
    border: none;
    color: #cee1ed;
  }

}