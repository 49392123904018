.eBill {
  @include subBodyBroadband();
  position: relative;
  padding-bottom: 40px;

  &.terms {
    min-height: 600px;
  }


  .body-container {

    padding: 10px;

    .title {

      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: #1448B6;
      padding: 2px 0 10px 2px;
    }

    .wrapper {
      width: 100%;
      height: auto;
      border: 1px solid #C8DBE8;
      box-sizing: border-box;
      border-radius: 7px;
      padding: 15px;
    }
  }

  @include customAlertViewTemp();
  @include TermCondistion(10px, 10px);

  .content {
    margin-top: 40px;

    &.sameMargin {
      margin: 20px 0;
    }

    .card {
      width: 390px;
      border: 1px solid #305991;
      box-sizing: border-box;
      border-radius: 7px;
      margin: auto;

      .form {
        padding: 1rem;
      }

      .title {
        padding: 10px;
      }

      > img {
        width: 64px;
        height: 64px;
        margin: auto;
        border-radius: 50%;
      }

      .pointer {
        cursor: pointer;
      }
    }
  }

  .connection {
    height: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    > div {
      display: flex;
      justify-content: space-between;
      width: 40%;
      margin-top: 0.6rem;
      border: 1px solid #305991;
      box-sizing: border-box;
      border-radius: 7px;
      align-items: center;
      padding: 0.6rem;
      font-weight: 400;
      font-size: 18px;
      cursor: pointer;

      @include screen('small'){
        width: 90%;
      }

    }
  }

  .imgDelete {
    width: 25px;
    height: 25px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMzkuNzUgMjk3Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2Y3MDMwMzt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMjI5LjU1LDE0NS43NGw0LjI3LTUxLjE3aDcuNDZBMTAuMjIsMTAuMjIsMCwwLDAsMjUxLjUsODQuMzVWNjUuNjlhMzEuOTQsMzEuOTQsMCwwLDAtMzEuOTEtMzEuOTFIMTg0VjI5LjA1QTI5LjA5LDI5LjA5LDAsMCwwLDE1NSwwSDEyNS4xNkEyOS4wOSwyOS4wOSwwLDAsMCw5Ni4xLDI5LjA1djQuNzNINjAuNTRBMzEuOTQsMzEuOTQsMCwwLDAsMjguNjMsNjUuNjlWODQuMzVBMTAuMjMsMTAuMjMsMCwwLDAsMzguODUsOTQuNTdoNy40Nkw2MC4yMSwyNjEuM0EzOS4xNSwzOS4xNSwwLDAsMCw5OSwyOTdoODIuMTJhMzkuMTcsMzkuMTcsMCwwLDAsMzguNzMtMzUsNjAuODIsNjAuODIsMCwwLDAsOS43LTExNi4yN1ptLTExMy0xMTYuNjlhOC42MSw4LjYxLDAsMCwxLDguNi04LjZIMTU1YTguNjEsOC42MSwwLDAsMSw4LjYsOC42djQuNzNoLTQ3Wk00OS4wNyw3NC4xMlY2NS42OUExMS40OSwxMS40OSwwLDAsMSw2MC41NCw1NC4yMkgyMTkuNTlhMTEuNDksMTEuNDksMCwwLDEsMTEuNDcsMTEuNDd2OC40M1pNMTgxLjEyLDI3Ni41NUg5OUExOC42LDE4LjYsMCwwLDEsODAuNTgsMjU5LjZsLTEzLjc1LTE2NUgyMTMuM2wtMy45Miw0Ny4wOGMtLjYyLDAtMS4yMi0uMDUtMS44NC0uMDVhNjAuODMsNjAuODMsMCwwLDAtOC41MywxMjFBMTguNiwxOC42LDAsMCwxLDE4MS4xMiwyNzYuNTVabTI2LjQyLTMzLjc0YTQwLjM4LDQwLjM4LDAsMSwxLDQwLjM5LTQwLjM4QTQwLjQyLDQwLjQyLDAsMCwxLDIwNy41NCwyNDIuODFaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjguNjMpIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMjIyLDIwMi40M2w5LjY0LTkuNjRhMTAuMjIsMTAuMjIsMCwwLDAtMTQuNDYtMTQuNDZMMjA3LjU0LDE4OGwtOS42NC05LjY0YTEwLjIyLDEwLjIyLDAsMCwwLTE0LjQ2LDE0LjQ2bDkuNjQsOS42NC05LjY0LDkuNjRhMTAuMjIsMTAuMjIsMCwwLDAsMTQuNDYsMTQuNDZsOS42NC05LjY0LDkuNjQsOS42NGExMC4yMiwxMC4yMiwwLDEsMCwxNC40Ni0xNC40NloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yOC42MykiLz48L3N2Zz4=);
    background-repeat: no-repeat;

    &:hover {
      cursor: pointer;
    }
  }

  .mobitel-container {

    width: fit-content;
    height: fit-content;
    margin: auto;
    //position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .mobitelLogo {
    border-radius: 12px;
    max-width: 220px;
  }

  .btn-mobitel {
    width: initial;
    height: initial;
    padding: 8px 25px;
    margin-left: 10px;
    background: #1979D1;
    box-shadow: none;
    border-radius: 7px;
    font-style: normal;
    border: none;
    font-weight: normal;
    font-size: 15px;
    text-align: center;
    color: #FFFFFF;
    transition: all 0.3 ease;
    display: block;
    margin: auto;
    margin-top: 20px;

    &:active {
      transform: scale(0.97, 0.97);
      transition: all 0.3 ease;
    }
  }

  .agreeCondition {
    margin-top: 2rem;
    display: flex;
    align-content: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    color: #565656;

    strong {
      color: #60c4ff;
      //font-weight: normal;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}