.broadBandPurhaseHistory {

  position: relative;
  @include customAlertViewTemp();

  margin-top: 0.6rem;
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 530px;
  height: auto;
  background: $panel-bg;
  box-shadow: $panel-box-shadow;
  border: $panel-border;

  .body-container {

    padding: 10px;

    .title {

      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: $dark-title-white;
      padding: 2px 0 10px 2px;
    }

    .wrapper {
      width: 100%;
      height: auto;
      box-sizing: border-box;
      border: 1px solid $panel-blue-border-color;
      border-radius: 7px;
      padding: 15px;
    }
  }

  .monthRow {
    display: flex;
    justify-content: space-between;
    margin: 0 1rem 0 1rem;

    & div {
      background: #EDEDED;
      width: 100%;
      margin: 1rem;
      padding: 4px;
      color: #000000;

      &.current {
        background: #EDF5FF;
        color: #1B2AA2;
      }
    }
  }

  .table {
    width: 98%;
    margin: 0.6rem;

    .usage {
      width: 45%;
      text-align: center;
    }

    .tableBody {
      font-size: 9px;
      color: $panel-text-color;
    }
  }

  .searchBar {
    margin: 0.6rem;
    width: 40%;

    > input {
      text-align: center;
      color: $input-text-color;
      background: $input-bg;
      border: $input-border;
    }
  }

  .pHButton {
    background-color: #175AAF;
    border: none;
    color: #FFFFFF;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 4px;
    cursor: pointer;
    border-radius: 8px;
    padding: 2px 15px;

  }

  .pHTable {

    .pHTHeader {
      //font-size: 9px;
      color: $panel-text-color;
      font-size: 16px;
      font-weight: bold;
    }

    .pHTBodyLight {
      background-color: $input-border;
      //color: #212529;
      font-size: 14px;
    }

    .pHTBodyDark {
      background-color: $nav-bg;
      //color: #212529;
      font-size: 14px;
    }

    .PHTDErr {
      position: relative;
      margin: 10px;
      padding: 10px;

      .pHTError {


        margin: auto;
        width: 60%;
        height: 60%;
        background: $common-container-bg;

        //border: 5px solid #73AD21;
        padding: 10px;
        text-align: center;
        border: 1px solid $common-containe-border-color;
        box-sizing: border-box;
        border-radius: 5px;
      }

    }


  }
}
