.changeBBDetails {
  @include subBodyBroadband();
  margin-top: 0.6rem;

  .body-container{

    padding: 10px;

    .title{

      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: #1448B6;
      padding: 2px 0 10px 2px;
    }
    .wrapper{
      width: 100%;
      height: auto;
      border: 1px solid #C8DBE8;
      box-sizing: border-box;
      border-radius: 7px;
      padding: 15px;
    }
  }
  @include customAlertViewTemp();
  .body {
    width: 100%;
    display: flex;
    justify-content: center;

    .form {
      margin-top: 40px;
      margin-bottom: 20px;
      padding: 15px;
      border: 1px solid #305991;
      box-sizing: border-box;
      border-radius: 7px;
      min-width: 350px;

      > div {
        margin: 0.5rem;
      }

      .dBlock {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .current-bill-mode {
          border: 1px solid #CFD1E4;
          border-radius: 5px;
          padding: 0.2em;
          // margin-left: 0.5em;
          // margin-right: 0.5em;
        }

        > svg {
          cursor: pointer;
        }
      }

      
    }
  }
}