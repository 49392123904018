.landingPage {
  //background: #0B2A58;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 1rem;
  position: relative;

  .imageBanner {
    width: 100%;
    border-radius: 10px;
    height: 400px;

    > img {
      width: 100%;
      max-height: 400px;
      border-radius: 10px;
    }
  }

  .body-container {
    border-radius: 12px;
    margin: auto;
    margin-top: 10px;

    @include TermCondistion(2rem, 0px);

  }
}

.buttonSet {
  display: flex;
  //flex-wrap: nowrap;
  margin: 2rem -0.5rem;
  flex-wrap: wrap;
  justify-content: space-evenly;

  &.noAcc {
    margin: 0.5rem;
  }
  .button-set-item {
    width: 100%;
    max-width: 320px;
    //min-width: 320px;
    margin: .5rem;
    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
    //border-radius: 50%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 280px;
    padding: 10px;


    &.noAcc {
      height: 250px;
    }

    .image-container {
      position: relative;
      width: 100%;
      height: 100%;
      //margin: 5%;

      > div {
        background-repeat: no-repeat;
        background-position: center;
      }

      .image-fiber {
        width: 100%;
        height: 100%;
        margin-top: 10px;
        //border-radius: 40%;
        background-image: url('../../../images/newLandingImage/slt_imgs_svg_new/fibre new img.svg');
        position: absolute;
      }

      .image-adsl {
        width: 100%;
        height: 100%;
        margin-top: 10px;
        //margin-top: 23px;
        //border-radius: 40%;
        background-image: url('../../../images/newLandingImage/slt_imgs_svg_new/adsl new img.svg');
        position: absolute;
      }

      .image-4g {
        width: 100%;
        height: 100%;
        margin-top: 5px;
        //margin-top: 10px;
        //border-radius: 20%;
        background-image: url('../../../images/newLandingImage/slt_imgs_svg_new/4g new img.svg');
        position: absolute;
      }


    }

    .payment-reg {
      display: flex;
      align-items: flex-end;
      
      .con-test {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 9px;
        line-height: 12px;
        
        color: #0056A3;
      }
      .con-free {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 23px;
        
        color: #FA5C39;
      }
    }
  }

}


.auth-container {
  padding: 1rem;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 500px;
  box-shadow: 0 3px 4px rgb(0 0 0 / 15%);
  border-radius: 12px;
  background-color: #0F3B7A;
  margin: auto;
  margin-top: 10%;
  @include customAlertViewSign('F');

  .title {
    @include subBodyBackgroundTitle()
  }

  .login-form {
    flex-basis: 100% !important;
  }

  .social {
    padding: 20px 0;
    position: relative;
    flex-basis: 50%;

    .social-container {
      position: relative;
      height: 100%;

      .box {
        display: flex;
        flex-direction: column;
        // align-items: center;
        .social-icons {
          margin-top: 10px;
        }

        .line {
          border-bottom: 0.5px solid #2c5a9a;

          span {
            color: $dark-title-white;
            font-size: 14px;
            padding-right: 10px;
            background-color: $panel-bg;
          }
        }
      }
    }
  }
}

.input-container-footer {
  p {

    color: #c3d3eb;
  }
}

.register-container {
  .register-label {
    color: #c3d3eb !important;

  }

  .btn-register {
    color: $link-color
  }
}

.input-container {
  img {
    position: absolute;
    right: 10px;
    top: 36px;
    &.changePassword {
      top: 50px;
      right: 25px;
    }
  }
}

nav .right-options-container {
  color: #fff;
  height: 50px;
}

.btn-back {
  display: flex;
  align-items: center;
  cursor: pointer;

  img {

    height: 22px;
    margin-right: 6px;
  }

  &.back {
    p {
      color: #e4e4e4;
      margin: 0;
      font-size: 14px;
      line-height: 14px;
    }
  }
}
