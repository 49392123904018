.getExtraGB {
  width: 100%;
  min-height: 430px;
  border-radius: 10px;
  background: $primary-color-white;
  margin-top: .6rem;
  grid-template-areas: "navSide graphbody";
  box-shadow: 0 0 6px #cee1ed;
  padding-top: 30px;

  &.noPadding {
    position: relative;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;


    padding-top: 0;
    @include customAlertView();
  }

  .hidePlan {
    width: calc( 100% - 1rem );
    color: $panel-text-color;
    margin-bottom: 28px;

  }

  .body-container{

    padding: 10px;

    .title{

      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: $panel-text-color;
      padding: 2px 0 10px 2px;
    }
    .wrapper{
      width: 100%;
      height: auto;
      border: 1px solid $panel-blue-border-color;
      box-sizing: border-box;
      border-radius: 7px;
      padding: 15px;
    }
  }
  .pricePlan {
    width: calc( 100% - 1rem );
    margin: auto;
    justify-content: center;

    @include screen("medium") {
width: 100%;
    }
    & div {
      background: $table-row-1;
      display: flex;
      justify-content: space-between;
      padding: 6px;
      margin-top: 5px;
      border-radius: 5px;

      &.blue {
        background: $table-row-2;
      }
    }
  }

  .selectGB {
    margin: 1rem;
    min-height: 100px;
    @include screen("medium") {
      margin: 1rem 0;
        }
    .leftSide {
      width: 40%;
      float: left;
      //padding-right: .6rem;
      
  @include screen("medium") {
width: 100%;
display: flex;
align-items: center;
justify-content: center;
margin: 1rem 0;
  }
      .tenGB {
        color: #1480FF;
        font-weight: 800;
        font-size: 73px;
        margin: 0 auto;
        height: auto;
        @include screen("medium") {
          margin:initial;
        }
      }

      .price {
        margin: 0 0 0 40px;
        font-weight: 600;
        color: $panel-text-color;
  @include screen("medium") {

    margin: 0 0 0 10px;
  }
      }
    }

    .rightSide {
      width: 50%;
      float: right;
      padding-right: .6rem;
      @include screen("medium") {
        width: 100%;
        padding-right: 0px;
          }
      .gbBox {
        display: flex;
        flex-wrap: wrap;

        & button {
          background: #EBF4FF;
          border-radius: 5px;
          color: #1480FF;
          font-weight: bold;
          font-size: 18px;
          width: 60px;
          height: 50px;
          margin: 5px;

          &.selected {
            transition: all 100ms linear;
            background: #1480FF;
            color: #FFFFFF;

          }
        }
        .extra-more{
          background: #f0f0f0;
    border-radius: 5px;
    color: #585858;
    font-weight: bold;
    font-size: 18px;
    width: fit-content;
    height: 50px;
    margin: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    border: 1px solid #d1d1d1;
    justify-content: center;
    padding:0 10px;
        }
      }

      .slider {
        margin: 30px 0;
        color: #0056A3;
        font-weight: bold;

        
    @include screen("medium") {
    padding: 0 24px;
      }}

      .nextButton {
        float: right;
        background: #1977d1;
        color: #fff;
        box-shadow: none;
        border: none;
        border-radius: 8px;  
        padding:0 25px;  
        height: 40px;
        box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
        font-size: 14px;
        font-weight: 600;
        line-height: 38px;
        margin-top: 30px;
      }
    }


  }
}
.MuiSlider-markLabel {
  color: white !important;
}

.MuiSlider-rail {
  background: white !important;
}

.MuiSlider-thumb {
  background: #1480FF !important;
}

.PrivateValueLabel {
  background: #1480FF !important;
}