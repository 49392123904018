.faqSubBody {
    margin-top: 0.6rem;
    box-shadow: 0 0 6px #cee1ed;
    border: 1px solid #C8DBE8;
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 530px;
    height: auto;
    background: $primary-color-white;
    position: relative;
  
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      color: #1448B6;
      border-bottom: 1px solid #C8DBE8;
  
      & p {
        margin: 0.6rem;
      }
    }

    .accordion{
        font-style: normal;
        font-weight: bold;
        background: #FAFAFA;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);

        .accordion-item{
            padding: 8px;
            .accordion-title{
            border-radius: 8px;
            height: 46px;
               & div {
                    display: inline-block;
                    color: #0056A3;
               }
            }

            .active, .accordion-title:hover {
                background: #FAFAFA;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
              }
            .accordion-content{
                color:#707070;
                background: #FAFAFA;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
            }
        }

        
    }

    // .active, .accordion:hover {
    //     background-color: #ccc; 
    //   }
  
    @include customAlertViewTemp()
  }
  