.complaintList {
  @include subBodyBroadband();
  position: relative;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: $dark-title-white;
    padding: 12px 0 20px 12px;
  }

  .complaintListContanner {
    // font-family: Open Sans;
    // padding: 20px;

    .complaintContanner {
      margin: 5px;
      width: 60%;
      padding: 20px;
      margin-left: auto;
      margin-right: auto;
      background: #2c5793;
      box-sizing: border-box;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);
      border-radius: 7px;
      line-height: 5px;

      .packageName {
        font-weight: bold;
        font-size: 16px;
        color: white;

        .complaintId {
          text-align: right;
          // font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          margin-left: 60%;

          color: white;

        }
      }

      .openDate {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;

        color: white;

      }
    }
  }

  .complaintButtonContanner {
    text-align: right;
    margin-right: 20%;
    padding: 40px;

    .complaintButton {
      background: linear-gradient(180deg, #1979D1 0%, #1658AD 100%);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 7px;

      text-align: center;
      color: #FFFFFF;
    }
  }
}
